import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import {
  Mutation as DesignMutationResults,
  MutationcreateShareableArgs,
  MutationupdateShareableArgs,
  MutationupdateShareablesArgs,
  Query as DesignQueryResults,
  QueryshareableByCodeArgs,
} from 'inkp-design-sdk/types.g';

import { DESIGN_FRAGMENT } from './designs';

export const SHAREABLE_FRAGMENT = gql`
  fragment ShareableFragment on Shareable {
    id
    name
    code
    email
    createdAt
    products {
      productId
      product {
        id
        colors {
          name
          hex
          sizes
        }
        designTemplates {
          side
          id
        }
      }
      selected
      colors {
        name
        selected
      }
      designId
      design {
        ...DesignFragment
      }
    }
  }

  ${DESIGN_FRAGMENT}
`;

export const SHAREABLE_BY_CODE_QUERY = gql`
  query ShareableByCode($code: String!) {
    shareableByCode(code: $code) {
      ...ShareableFragment
    }
  }
  ${SHAREABLE_FRAGMENT}
`;
export class QueryShareableByCode extends Query<DesignQueryResults, QueryshareableByCodeArgs> {}

export const SHAREABLES_BY_USER = gql`
  query ShareablesByUser($includeDeleted: Boolean = false) {
    shareables: shareablesByUser(includeDeleted: $includeDeleted) {
      ...ShareableFragment
    }
  }
  ${SHAREABLE_FRAGMENT}
`;

export const SHAREABLES_BY_USER_EMAIL = gql`
  query ShareablesByUserEmail($email: String!, $includeDeleted: Boolean = false) {
    shareables: shareablesByUserEmail(userEmail: $email, includeDeleted: $includeDeleted) {
      ...ShareableFragment
    }
  }
  ${SHAREABLE_FRAGMENT}
`;

export const MUTATION_CREATE_SHARABLE = gql`
  mutation CreateShareable(
    $shareable: NewShareableInput!
    $products: [ShareableProducItemInput!]!
    $designs: [ShareableDesignInput!]!
  ) {
    createShareable(shareable: $shareable, products: $products, designs: $designs) {
      ...ShareableFragment
    }
  }
  ${SHAREABLE_FRAGMENT}
`;
export class MutationCreateSharable extends Mutation<DesignMutationResults, MutationcreateShareableArgs> {}

export const MUTATION_UPDATE_SHARABLE = gql`
  mutation UpdateShareable(
    $shareableId: String!
    $shareable: NewShareableInput!
    $products: [ShareableProducItemInput!]!
    $designs: [ShareableDesignInput!]!
  ) {
    updateShareable(shareableId: $shareableId, shareable: $shareable, products: $products, designs: $designs) {
      ...ShareableFragment
    }
  }
  ${SHAREABLE_FRAGMENT}
`;

export class MutationUpdateSharable extends Mutation<DesignMutationResults, MutationupdateShareableArgs> {}

export const MUTATION_UPDATE_SHAREABLES = gql`
  mutation UpdateShareables($shareablesInput: [ShareableInput!]!) {
    updateShareables(shareablesInput: $shareablesInput) {
      ...ShareableFragment
    }
  }
  ${SHAREABLE_FRAGMENT}
`;

export class MutationUpdateShareables extends Mutation<DesignMutationResults, MutationupdateShareablesArgs> {}
