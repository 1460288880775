import * as _ from 'lodash';
import * as React from 'react';
import gql from 'graphql-tag';
import { Mutation, MutationFn, Query } from 'react-apollo';
import { Link } from 'react-router-dom';
import copy from 'copy-to-clipboard';

import mockupRoutes from 'inkp-mockup-sdk/routes';
import { validateEmail } from 'inkp-user-sdk/user';
import { Shareable } from 'inkp-design-sdk/types.g';
import { url, path, routes } from 'inkp-routes/public';
import {
  Mutation as OrderMutationResults,
  Query as OrderQueryResults,
  MutationupdateCartArgs,
} from 'inkp-order-sdk/types.g';

import Modal from 'inkp-components/dist/Components/Modal';
import Input from 'inkp-components/dist/Components/Input';
import Button from 'inkp-components/dist/Components/Button';

import { findServiceError } from '../../../util/apollo';
import { SHAREABLE_FRAGMENT } from '../../../graphql/shareable';
import {
  MUTATION_UPDATE_USER,
  MutationUpdateUser,
  MUTATION_SIGN_IN,
  MutationSignIn,
  updateCurrentUser,
} from '../../../graphql/users';
import { QUERY_CART_BY_USER } from '../../../util/Checkout';
import { User, MutationupdateUserArgs } from 'inkp-user-sdk/types.g';
import { isUserRegistered } from 'inkp-user-sdk/user';

export type SaveScene = 'save' | 'share' | 'option' | 'shared';

const MOCKUP_SIZE: string = 'medium';

const SAVE_DESIGN_MUTATION = gql`
  mutation CreateShareable(
    $shareable: NewShareableInput!
    $products: [ShareableProducItemInput!]!
    $designs: [ShareableDesignInput!]!
  ) {
    createShareable(shareable: $shareable, products: $products, designs: $designs) {
      ...ShareableFragment
    }
  }
  ${SHAREABLE_FRAGMENT}
`;

const UPDATE_DESIGN_MUTATION = gql`
  mutation UpdateShareable(
    $shareableId: String!
    $shareable: NewShareableInput!
    $products: [ShareableProducItemInput!]!
    $designs: [ShareableDesignInput!]!
  ) {
    updateShareable(shareableId: $shareableId, shareable: $shareable, products: $products, designs: $designs) {
      ...ShareableFragment
    }
  }
  ${SHAREABLE_FRAGMENT}
`;

const SEND_DESIGN_EMAIL_MUTATION = gql`
  mutation SendShareDesignEmail($code: String!, $shareEmails: [String!]!) {
    sendShareableEmail(code: $code, shareEmails: $shareEmails)
  }
`;

const SHARE_PROMO = 'SHAREPROMO';
class MutationUpdateCart extends Mutation<OrderMutationResults, MutationupdateCartArgs> {}
const UPDATE_CART_MUTATION = gql`
  mutation UpdateCart($id: ID!, $data: UpdatableCartFields!) {
    updateCart(id: $id, data: $data) {
      id
      coupons {
        code
      }
    }
  }
`;

class CartQuery extends Query<OrderQueryResults> {}
const CART_QUERY = gql`
  query CartByUser {
    cartByUser {
      id
    }
  }
`;

function isDesignNew(props: any): boolean {
  if (!props.shareable && !props.duplicate) {
    return true;
  }
  if (!props.shareable && props.designName !== props.duplicate.name) {
    return true;
  }
  if (!props.shareable && props.email !== props.duplicate.email && props.duplicate.email !== null) {
    return true;
  }

  return false;
}

function normalizeProducts(products: any, selectedProduct: any, selectedItem: any) {
  return products.map((product: any) => {
    const result = Object.assign({}, product);
    if (result.productId === selectedProduct.id) {
      result.selected = true;
    }

    result.colors = result.colors.map((color: string) => {
      return {
        name: color,
        selected: selectedItem.name === color ? true : undefined,
      };
    });

    delete result.designId;

    return result;
  });
}

function OptionScene(props: { onChangeScene: (scene: 'share') => void; onContinue: () => void }) {
  return (
    <div className="px-1p5 pb-1p5">
      <div>What do you want to do next?</div>
      <div className="bgc-gray-50 br py-1 px-1p5 flex mt-1p5 cursor-pointer items-center" onClick={props.onContinue}>
        <div>
          <div className="fw-bold">Select Size & Quantities</div>
          <div className="fs-xs">and continue to Cart & Checkout</div>
        </div>
        <div>
          <i className="fs-icon-1p5 mdi mdi-chevron-right color-navy-500" />
        </div>
      </div>
      <div
        className="bgc-gray-50 br py-1 px-1p5 flex mt-1 cursor-pointer items-center"
        onClick={() => props.onChangeScene('share')}
      >
        <div>
          <div className="fw-bold">Share Design</div>
          <div className="fs-xs">and get $1 off your order!</div>
        </div>
        <div>
          <i className="fs-icon-1p5 mdi mdi-chevron-right color-navy-500" />
        </div>
      </div>
    </div>
  );
}

function SharedScene(props: { onCancel: () => void; onContinue: () => void }) {
  return (
    <div>
      <div className="brt bgc-purple">
        <img src="https://inkp-production.32pt.com/public/assets/share-design-dollar-off.png" />
      </div>
      <div className="py-1p5 ta-center">
        <div className="fw-bold fs-lg">$1 off applied to your order!</div>
        <Button className="mt-1p5" onClick={props.onCancel}>
          Back to Design
        </Button>
        <div className="mt-1 color-navy-500 cursor-pointer" onClick={props.onContinue}>
          Proceed to Cart
        </div>
      </div>
    </div>
  );
}

function ShareScene(props: {
  userId: string;
  cart: boolean;
  shareUrl: string;
  shareCode: string;
  onAddEmail: (email: string) => void;
  onRemoveEmail: (email: string) => void;
  onShare?: () => void;
  onCancel: () => void;
  onContinue: () => void;
  shareEmail: string;
  shareEmails: string[];
  onFieldChange: (field: string, value: string) => void;
  onChangeScene: (scene: 'shared') => void;
}) {
  const [copied, setCopied] = React.useState(false);
  return (
    <CartQuery query={CART_QUERY}>
      {({ error: cartQueryError, loading: cartLoading, data: cartResults }) => {
        return (
          <div className="px-1p5 pb-1p5">
            <div className="ta-center">
              <div>
                <img src="https://inkp-production.32pt.com/public/logos/inkpop-share.svg" />
              </div>
              <div className="mt-p5 fs-lg fw-bold">
                Share your awesome design
                <br />& get $1 off your order!
              </div>
              <div className="mt-1p5">
                <span className="color-navy-500 mr-1 d-ib">{props.shareUrl}</span>
                <span
                  className="cursor-pointer color-primary fw-bold d-ib"
                  onClick={() => {
                    copy(props.shareUrl);
                    setCopied(true);
                  }}
                >
                  {copied ? 'Copied!' : 'Copy Link'}
                </span>
              </div>
            </div>
            <form
              className="mt-2"
              onSubmit={(e) => {
                e.preventDefault();
                if (!validateEmail(props.shareEmail).valid) return;
                props.onAddEmail(props.shareEmail);
              }}
            >
              <Input
                label="Email Addresses"
                rightIcon={validateEmail(props.shareEmail).valid ? 'plus-circle color-navy-500' : ''}
                onRightIconClick={() => props.onAddEmail(props.shareEmail)}
                value={props.shareEmail}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  props.onFieldChange('shareEmail', e.target.value.toLowerCase())
                }
              />
            </form>
            {props.shareEmails.length > 0 && (
              <div className="mt-1p5">
                <div className="fw-bold fs-xs pb-p75 bwb-1 bc-gray">Sending to</div>
                {props.shareEmails.map((email) => (
                  <div key={email} className="bwb-1 bc-gray py-1 flex pr-p5">
                    <div className="flex-1">{email}</div>
                    <div onClick={() => props.onRemoveEmail(email)}>
                      <i className="mdi mdi-close-circle fs-icon-1p5 color-navy-500 cursor-pointer" />
                    </div>
                  </div>
                ))}
              </div>
            )}
            <div className="ta-right mt-1p5">
              <span
                className="mr-1p5 cursor-pointer color-navy-500"
                onClick={props.cart ? props.onContinue : props.onCancel}
              >
                Maybe Later
              </span>

              <MutationUpdateCart mutation={UPDATE_CART_MUTATION}>
                {(updateCart, { loading: updateCartLoading, error: updateCartError, data: updateCartData }) => {
                  const cartId = cartResults && cartResults.cartByUser && cartResults.cartByUser.id;
                  const existingCoupons =
                    (cartResults && cartResults.cartByUser && cartResults.cartByUser.coupons) || [];
                  return (
                    <Mutation
                      mutation={SEND_DESIGN_EMAIL_MUTATION}
                      onCompleted={() => props.onChangeScene('shared')}
                      onError={() => {
                        console.log('Cannot share designs');
                      }}
                    >
                      {(sendShareableEmail: MutationFn, sendShareableEmailResult: any) => (
                        <Button
                          disabled={props.shareEmails.length === 0 && !validateEmail(props.shareEmail).valid}
                          onClick={() => {
                            let shareEmails = props.shareEmails
                              .concat([props.shareEmail])
                              .filter((_email) => validateEmail(_email).valid);
                            sendShareableEmail({ variables: { code: props.shareCode, shareEmails } });
                            const shareCoupon = existingCoupons.find((_coupon) => _coupon.code === SHARE_PROMO);
                            const coupons = shareCoupon
                              ? existingCoupons
                              : existingCoupons.concat([{ code: SHARE_PROMO }]);
                            updateCart({ variables: { id: cartId!, data: { coupons } } });
                          }}
                        >
                          Share Design
                        </Button>
                      )}
                    </Mutation>
                  );
                }}
              </MutationUpdateCart>
            </div>
          </div>
        );
      }}
    </CartQuery>
  );
}

export default function SaveDesignModal(props: {
  currentUser: any;
  userId: string;
  designName: string;
  email: string;
  password: string;
  designs: any;
  products: any;
  loggedIn: boolean;
  onFieldChange: (field: string, value: string) => void;
  onSaved: (shareable: any, mockupUrl?: string) => void;
  duplicate: {
    id: string;
    email: string | null;
    name: string;
  } | null;
  onDuplicate: (duplicate: { id: string; name: string; email: string }) => void;
  onCancel: () => void;
  selectedItem: any;
  selectedProduct: any;
  selectedTemplate: any;
  selectedColor: string;
  shareUrl: string;
  shareCode: string;
  onAddShareEmail: (email: string) => void;
  onRemoveShareEmail: (email: string) => void;
  scene: SaveScene;
  onContinue: () => void;
  onChangeScene: (scene: SaveScene) => void;
  shareEmail: string;
  shareEmails: string[];
  onEmailInUse: (email: string) => void;
  emailInUse: string | null;
  onFieldError: (fieldErrors: { valid: boolean; field: string; message: string }[]) => void;
  fieldErrors: { valid: boolean; field: string; message: string }[];
  cart: boolean;
  shareable?: Shareable;
}) {
  const { currentUser } = props;

  return (
    <MutationUpdateUser
      mutation={MUTATION_UPDATE_USER}
      onCompleted={(data: { updateUser: User }) => {
        props.cart ? props.onContinue() : props.onCancel();
      }}
    >
      {(updateUser) => {
        return (
          <Mutation
            mutation={SAVE_DESIGN_MUTATION}
            onCompleted={(data: { createShareable: Shareable }) => {
              const product = data.createShareable.products[0];
              const newDesignId = product.designId;
              const preloadMockupUrl = url('mockup', mockupRoutes.mockup, {
                designId: newDesignId,
                templateIdentifier: props.selectedTemplate && props.selectedTemplate.id,
                color: props.selectedItem && props.selectedItem.hex,
                side: props.selectedTemplate && props.selectedTemplate.side,
                size: MOCKUP_SIZE,
              });
              // begin mockup generation early
              const preloadedImg = new Image();
              preloadedImg.src = preloadMockupUrl;
              props.onSaved(data.createShareable, preloadMockupUrl);
              // if the user is registered then the updateUser mutation to add the email to the ANON user will
              // not be executed
              if (isUserRegistered(currentUser) || !data.createShareable.email) {
                props.cart ? props.onContinue() : props.onCancel();
                return;
              }
              updateUser({
                variables: {
                  userData: {
                    userId: currentUser.id,
                    email: data.createShareable.email,
                  },
                },
              });
            }}
            onError={(error: any) => {
              const serviceError = findServiceError(error);
              if (serviceError && serviceError.extensions) {
                if (serviceError.extensions.code === 'DUPLICATE_DESIGN_NAME') {
                  props.onDuplicate(serviceError.data.data);
                }

                if (serviceError.extensions.code === 'SHAREABLE_EMAIL_IN_USE') {
                  props.onEmailInUse(props.email);
                }
              }
            }}
          >
            {(createShareable: MutationFn, createResult: any) => {
              return (
                <Mutation
                  mutation={UPDATE_DESIGN_MUTATION}
                  onCompleted={(data: { updateShareable: Shareable }) => {
                    props.onSaved(data.updateShareable);
                    props.cart ? props.onContinue() : props.onCancel();
                  }}
                  onError={(error: any) => {
                    const serviceError = findServiceError(error);
                    if (serviceError && serviceError.extensions) {
                      if (serviceError.extensions.code === 'DUPLICATE_DESIGN_NAME') {
                        props.onDuplicate(serviceError.data.data);
                      }
                    }
                  }}
                >
                  {(updateShareable: MutationFn, updateResult: any) => {
                    const isNew = isDesignNew(props);
                    const save = () => {
                      if (isNew) {
                        createShareable({
                          variables: {
                            shareable: {
                              name: props.designName,
                              email: props.email,
                            },
                            products: normalizeProducts(props.products, props.selectedProduct, props.selectedItem),
                            designs: props.designs,
                          },
                        });
                      } else {
                        updateShareable({
                          variables: {
                            shareableId: (props.shareable || props.duplicate)!.id,
                            shareable: {
                              name: props.designName,
                              email: props.email,
                            },
                            products: normalizeProducts(props.products, props.selectedProduct, props.selectedItem),
                            designs: props.designs,
                          },
                        });
                      }
                    };

                    return (
                      <MutationSignIn
                        mutation={MUTATION_SIGN_IN}
                        onCompleted={save}
                        update={(cache: any, { data }: any) => updateCurrentUser(cache, data.signIn)}
                        refetchQueries={[{ query: QUERY_CART_BY_USER }]}
                        onError={(error: any) => {
                          const serviceError = findServiceError(error);
                          if (
                            serviceError &&
                            serviceError.extensions &&
                            serviceError.extensions.code === 'INVALID_CREDENTIALS'
                          ) {
                            return props.onFieldError([
                              { valid: false, field: serviceError.data.data.field, message: serviceError.data.message },
                            ]);
                          }
                        }}
                      >
                        {(signIn: MutationFn, signInResult: any) => {
                          let title = 'Save Your Design';
                          if (props.shareable) {
                            title = 'Edit Design';
                          }
                          const saved = !!(
                            (updateResult.data && updateResult.data.updateShareable) ||
                            (createResult.data && createResult.data.createShareable)
                          );
                          const loading = updateResult.loading || createResult.loading || signInResult.loading;
                          if (saved && props.scene !== 'save') {
                            title = 'Design Saved!';
                          }

                          if (loading) {
                            title = 'Saving Design...';
                          }

                          if (props.scene === 'shared') {
                            title = '';
                          }

                          const submittable = props.designName && (props.loggedIn || props.email);

                          const submit = () => {
                            if (!submittable) return;

                            props.onFieldError([]);

                            if (props.email && !validateEmail(props.email).valid) {
                              return props.onFieldError([
                                { valid: false, field: 'email', message: 'Invalid email format' },
                              ]);
                            }

                            if (props.emailInUse) {
                              signIn({
                                variables: {
                                  email: props.email,
                                  password: props.password,
                                },
                              });
                            } else {
                              save();
                            }
                          };

                          const emailError = _.find(props.fieldErrors, { field: 'email' });
                          const passwordError = _.find(props.fieldErrors, { field: 'password' });
                          const onNext =
                            props.cart && (props.scene === 'share' || props.scene === 'shared')
                              ? props.onContinue
                              : props.onCancel;
                          return (
                            <Modal
                              title={title}
                              headerStyle={props.scene === 'shared' ? 'dark' : 'light'}
                              headerTextAlignment="left"
                              onCloseClick={onNext}
                              onOverlayClick={onNext}
                            >
                              {props.scene === 'save' && (
                                <div>
                                  {props.duplicate && (
                                    <div className="py-1 px-1p5 flex bgc-yellow-50 mb-1p5">
                                      <div className="mr-p5">
                                        <i className="mdi mdi-alert fs-icon-1p5 color-yellow" />
                                      </div>
                                      <div className="flex-1 mr-p5">
                                        <div className="fw-bold">
                                          {props.shareable ? 'Invalid Name' : 'Replace Design'}
                                        </div>
                                        <div className="mt-p25 fs-xs">
                                          This design name already exists.
                                          {/** Cannot replace another shareable when updating one **/}
                                          {!props.shareable && (
                                            <span>
                                              &nbsp;Do you want to replace {props.duplicate.name}? If not, you may
                                              update the name below.
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {props.emailInUse && (
                                    <div className="px-1p5  mb-1p5">
                                      Looks like you already have an account.
                                      <br />
                                      Please sign in below to continue.
                                    </div>
                                  )}
                                  <div className="px-1p5 mb-1p5">
                                    <form
                                      onSubmit={(e) => {
                                        e.preventDefault();
                                        submit();
                                      }}
                                    >
                                      <div className="mb-1p5">
                                        <Input
                                          label="Design Name"
                                          autoFocus={true}
                                          value={props.designName}
                                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            props.onFieldChange('designName', e.target.value)
                                          }
                                        />
                                      </div>
                                      {/** Keep the email field display even after login if the user was not logged in and we prompt them to */}
                                      {!props.shareable && (!props.loggedIn || props.emailInUse) && (
                                        <div className="mb-1p5">
                                          <Input
                                            label="Email"
                                            inputType="email"
                                            value={props.email}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                              props.onFieldChange('email', e.target.value.toLowerCase())
                                            }
                                            error={emailError && !emailError.valid}
                                            helper={emailError ? emailError.message : ''}
                                            helperColor={emailError && !emailError.valid ? 'red' : 'green'}
                                          />
                                        </div>
                                      )}
                                      {/** Email found to be tied with an account, prompt login */}
                                      {!props.shareable && props.emailInUse && (
                                        <div className="mb-1p5">
                                          <Input
                                            label="Password"
                                            inputType="password"
                                            defaultValue={props.password}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                              props.onFieldChange('password', e.target.value)
                                            }
                                            error={passwordError && !passwordError.valid}
                                            helper={passwordError && passwordError.message}
                                            helperColor={passwordError && !passwordError.valid ? 'red' : 'green'}
                                          />
                                        </div>
                                      )}
                                      <button type="submit" className="d-n" />
                                    </form>
                                    <div className="flex items-center">
                                      {props.emailInUse && (
                                        <div>
                                          <Link
                                            to={path(routes.app.account.forgotPassword, { email: props.email })}
                                            className="color-blue"
                                          >
                                            Forgot Password
                                          </Link>
                                        </div>
                                      )}
                                      <div className="ta-right flex-1">
                                        <Button type="text" color="secondary" onClick={props.onCancel}>
                                          Cancel
                                        </Button>
                                        <Button
                                          className="ml-1p5"
                                          disabled={!submittable || loading}
                                          onClick={() => submit()}
                                        >
                                          {isNew || props.shareable ? 'Save' : 'Replace & Save'}
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {props.scene === 'option' && (
                                <OptionScene onChangeScene={props.onChangeScene} onContinue={props.onContinue} />
                              )}
                              {props.scene === 'share' && (
                                <ShareScene
                                  userId={props.userId}
                                  shareUrl={props.shareUrl}
                                  shareCode={props.shareCode}
                                  onAddEmail={props.onAddShareEmail}
                                  onRemoveEmail={props.onRemoveShareEmail}
                                  onFieldChange={props.onFieldChange}
                                  shareEmail={props.shareEmail}
                                  shareEmails={props.shareEmails}
                                  onCancel={props.onCancel}
                                  onContinue={props.onContinue}
                                  onChangeScene={props.onChangeScene}
                                  cart={props.cart}
                                />
                              )}
                              {props.scene === 'shared' && (
                                <SharedScene onCancel={props.onCancel} onContinue={props.onContinue} />
                              )}
                            </Modal>
                          );
                        }}
                      </MutationSignIn>
                    );
                  }}
                </Mutation>
              );
            }}
          </Mutation>
        );
      }}
    </MutationUpdateUser>
  );
}
