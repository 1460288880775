import * as React from 'react';
import _ from 'lodash';
import gql from 'graphql-tag';
import { Query, Mutation } from 'react-apollo';
import classnames from 'classnames';

import mockupRoutes from 'inkp-mockup-sdk/routes';
import { routes, url, path } from 'inkp-routes';
import { Design, Shareable } from 'inkp-design-sdk/types.g';
import {
  Product,
  DesignTemplate,
  ProductColor,
  Query as QueryProductResults,
  parseProductItemId,
} from 'inkp-product-sdk';
import { User } from 'inkp-user-sdk/types.g';
import { StateContainer, Subscribe } from 'infra-frontend/helpers/apollo';
import DesignDisplayer from 'inkp-components/dist/Components/DesignDisplayer';
import ProductCard, { Props as Productprops } from 'inkp-components/dist/Components/ProductCard';
import Modal from 'inkp-components/dist/Components/Modal';
import Button from 'inkp-components/dist/Components/Button';
import Loading from 'inkp-components/dist/Components/Loading';

import ProductsQuantitySelector from '../QuantitySelector';
import SaveDesignModal from '../../pages/DesignTool/SaveDesignModal';
import CartDrawer from '../CartDrawer';
import { SHAREABLES_BY_USER, SHAREABLES_BY_USER_EMAIL } from '../../graphql/shareable';
import { QUERY_CART_BY_USER } from '../../util/Checkout';
import { QUERY_PRODUCTS_BY_ID } from '../../graphql/products';
import AbTest, { AbVariation } from '../HOC/AbTest';

interface Props {
  currentUser: User;
  userId?: string;
  userEmail: string;
  onAddToCartClick?: (cartId: string) => void;
  onStartDesignClick: (productId: any, color: string) => void;
}

class QueryProducts extends Query<QueryProductResults> {}

interface ProductPropsWithColor extends Productprops {
  color: string;
}
const ITEMS_NUMBER_FOR_STARTING_PRICE = 50;

const DELETE_SHAREABLE_MUTATION = gql`
  mutation DeleteShareable($shareableId: String!) {
    shareable: deleteShareable(shareableId: $shareableId) {
      id
    }
  }
`;

const PRODUCTS_QUERY = gql`
  query GetProducts {
    productInfo: filterProducts(
      sortBy: { field: "rank", order: 1 }
      page: 1
      perPage: 4
      categories: { name: "t-shirts", scope: "type" }
    ) {
      items {
        id
        name
        detailTemplates {
          id
          side
        }
        sides {
          name
          printZonesObjects {
            id
          }
        }
        colors {
          name
          hex
          startingPrice
          class
          sizes
        }
        image {
          label
          url
        }
      }
    }
  }
`;

const DEFAULT_MOCKUP_SIDE = 'front';

interface State {
  shareEmails: string[];
  shareEmail: string;
  showShareModal: boolean;
  shareDesignId: string;
  designName: string;
  shareCode: string;
  showProductPicker: boolean;
  selectedDesign: string;
  productColorOptions: string[];
  selectedProduct: string;
}

interface SavedDesignsState {
  addShareEmail: (email: string) => void;
  removeShareEmail: (email: string) => void;
  setSelectedShareable: (designName: string, shareCode: string) => void;
  setSelectedDesignAndProduct: (designId: string, shareCode: string, productId: string, colors: string[]) => void;
  toogleShareModal: () => void;
  onEmailChange: (field: string, value: string) => void;
  closeProductQuantityPicker: () => void;
  state: State;
}

class TypedSubscribe extends Subscribe<[SavedDesignsState]> {}

class SavedDesignsState extends StateContainer {
  initialState: State = {
    shareEmails: [],
    shareEmail: '',
    showShareModal: false,
    shareDesignId: '',
    designName: '',
    shareCode: '',
    selectedDesign: '',
    showProductPicker: false,
    productColorOptions: [],
    selectedProduct: '',
  };

  shape = `
    {
      shareEmails
      shareEmail
      showShareModal
      shareDesignId
      designName
      shareCode
      showProductPicker
      selectedDesign
      productColorOptions
      selectedProduct
    }
  `;

  addShareEmail = (email: string) => {
    this.setState({
      shareEmails: Array.from(new Set(this.state.shareEmails.concat([email]))),
      shareEmail: '',
    });
  };

  removeShareEmail = (emailToRemove: string) => {
    this.setState({
      shareEmails: this.state.shareEmails.filter((email: string) => email !== emailToRemove),
    });
  };

  setSelectedShareable = (designName: string, shareCode: string) => {
    this.setState({
      showShareModal: true,
      designName,
      shareCode,
    });
  };

  toogleShareModal = () => {
    this.setState((state: State) => {
      return {
        showShareModal: !state.showShareModal,
        shareEmail: '',
        shareEmails: [],
      };
    });
  };

  onEmailChange = (field: string, value: string) => {
    if (field !== 'shareEmail') {
      return;
    }

    this.setState({
      shareEmail: value,
    });
  };

  setSelectedDesignAndProduct = (designId: string, sharecode: string, productId: string, colors: string[]) => {
    this.setState({
      selectedDesign: designId,
      showProductPicker: true,
      productColorOptions: colors,
      selectedProduct: productId,
      shareCode: sharecode,
    });
  };

  closeProductQuantityPicker = () => {
    this.setState({
      showProductPicker: false,
    });
  };
}

const renderLoadingOrError = (loading: boolean, error: boolean) => {
  if (loading) {
    return <Loading size="large" />;
  }
  if (error) {
    return <div>Error</div>;
  }
};

const getSelectedShareableSelectedColor = (shareables: Shareable[], selectedShareableCode: string): string[] => {
  const shareable = shareables.find((shareable: Shareable) => {
    return shareable.id === selectedShareableCode;
  });

  if (!shareable) {
    return [];
  }

  if (!shareable || !shareable.products || shareable.products.length < 1) {
    return [];
  }

  const productColors = shareable.products[0].colors;

  const selectedColor = productColors.find((color: any) => {
    return color.selected;
  });

  if (!selectedColor) {
    return productColors.map((color) => {
      return color.name;
    });
  }

  return [
    selectedColor.name,
    ...productColors
      .filter((color) => {
        return color.name !== selectedColor.name;
      })
      .map((color) => {
        return color.name;
      }),
  ];
};

const renderUserDesigns = (
  shareables: Shareable[],
  onShareClick: (designName: string, shareCode: string) => void,
  deleteShareable: (variables: { variables: { shareableId: string } }) => void,
  setSelectedDesignAndProduct: (designId: string, shareCode: string, productId: string, colors: string[]) => void
) => {
  const sortedShareables = shareables.sort((shareable1, shareable2) => {
    const time1 = new Date(shareable1.products[0].design!.updatedAt).getTime();
    const time2 = new Date(shareable2.products[0].design!.updatedAt).getTime();
    return time2 - time1;
  });
  return sortedShareables.map((shareable, index) => {
    const shareableProduct = shareable.products[0];
    const design = shareableProduct.design as Design;
    const sideName = design.sides[0].name;
    const product = (shareableProduct as any).product as Product;
    const templateIdentifier = product.designTemplates.find(
      (template) => (template.side as string) == sideName
    ) as DesignTemplate;
    let selectedColor = shareableProduct.colors.find((color) => {
      return color.selected == true;
    });
    // If there's no color selected, we set the first color on the array
    if (!selectedColor) {
      selectedColor = shareableProduct.colors[0];
    }
    const selectedColorHex = product.colors.find((color) => color.name == selectedColor!.name)!.hex;
    const mockupUrl = getMockupUrl(
      design.id,
      selectedColorHex ? selectedColorHex : 'ffffff',
      templateIdentifier.id,
      sideName
    );
    const colorSelecttionvalues = shareableProduct.colors.map((color: ProductColor) => {
      return color.hex;
    });
    return (
      <div
        key={`shareable-${shareable.id}`}
        className={classnames('w-full mb-1p5 md:w-1/2 pt-0 md:w-1/2 ', `${index % 2 ? 'md:pl-1' : 'md:pr-1'}`)}
      >
        <DesignDisplayer
          designName={shareable.name}
          lastEditDate={formatDate(design!.updatedAt)}
          imageSrc={mockupUrl}
          editDesignUrl={path(routes.app.account.editDesign, { code: shareable.code })}
          onShareClick={() => {
            onShareClick(shareable.name, shareable.code);
          }}
          onDeleteClick={() => {
            deleteShareable({ variables: { shareableId: shareable.id } });
          }}
          onBuyNowClick={() => {
            setSelectedDesignAndProduct(design.id, shareable.id, product.id, colorSelecttionvalues);
          }}
        />
      </div>
    );
  });
};

const getMockupUrl = (designId: string, color: string, templateIdentifier: string, side: string) => {
  return url('mockup', mockupRoutes.mockup, { templateIdentifier, designId, color, size: 'medium', side });
};

const getMockupBlankUrl = (color: string, templateIdentifier: string) => {
  return url('mockup', mockupRoutes.blank, { templateIdentifier, color, size: 'medium' });
};

const formatDate = (date: Date): string => {
  const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  const currentDate = new Date(date);
  return `${currentDate.getDate()} ${months[currentDate.getMonth()]} ${currentDate.getFullYear()}`;
};

export default class SavedDesigns extends React.Component<Props> {
  render() {
    const { userId, userEmail, onAddToCartClick, onStartDesignClick, currentUser } = this.props;

    return (
      <TypedSubscribe to={[SavedDesignsState]} namespace="DesignTool">
        {(savedDesignsContainer: SavedDesignsState) => {
          const {
            state,
            toogleShareModal,
            setSelectedShareable,
            setSelectedDesignAndProduct,
            closeProductQuantityPicker,
          } = savedDesignsContainer;
          const { selectedDesign, selectedProduct, shareCode } = state;
          return (
            <Query query={QUERY_CART_BY_USER}>
              {({ cartLoading, cartError, data: cartData }: any) => {
                const cart = cartData && cartData.cartByUser;
                const productIds = _.uniq(
                  _.compact(
                    _.map((cart && cart.items) || [], (cartItem) => {
                      const { productId } = parseProductItemId(cartItem.product.productItemId);
                      return productId;
                    }).concat(selectedProduct)
                  )
                );
                return (
                  <div>
                    {renderLoadingOrError(cartLoading, cartError)}
                    {!cartLoading && !cartError && (
                      <Query
                        query={userId ? SHAREABLES_BY_USER : SHAREABLES_BY_USER_EMAIL}
                        variables={{ email: userEmail, includeDeleted: false }}
                        fetchPolicy="network-only"
                      >
                        {({ loading, error, refetch: refetchShareables, data }: any) => {
                          const shareables: Shareable[] | undefined = data && data.shareables;
                          const selectedShareable =
                            shareables &&
                            shareables.find((shareable) => {
                              return shareable.products[0].designId === selectedDesign;
                            });
                          return (
                            <Mutation
                              mutation={DELETE_SHAREABLE_MUTATION}
                              onCompleted={() => {
                                refetchShareables();
                              }}
                            >
                              {(deleteShareable: (variables: { variables: { shareableId: string } }) => void) => {
                                return (
                                  <div className="mt-2">
                                    {renderLoadingOrError(loading, error)}
                                    {!error && !loading && shareables && (
                                      <div>
                                        {shareables.length > 0 && (
                                          <div>
                                            <div className="w-full mb-1p5 d-ib flex items-center justify-between">
                                              <span className="float-left fs-xl fw-bold">Saved Designs</span>
                                              <span className="float-right fw-bold color-navy-500">
                                                {shareables.length} {shareables.length > 1 ? 'Designs' : 'Design'}
                                              </span>
                                            </div>
                                            <div className="w-full flex md:flex-wrap flex-col md:flex-row">
                                              {renderUserDesigns(
                                                shareables,
                                                setSelectedShareable,
                                                deleteShareable,
                                                setSelectedDesignAndProduct
                                              )}
                                              {state.showShareModal && (
                                                <Modal
                                                  onCloseClick={() => {
                                                    toogleShareModal;
                                                  }}
                                                >
                                                  {renderShareModal(savedDesignsContainer, currentUser)}
                                                </Modal>
                                              )}
                                            </div>
                                          </div>
                                        )}
                                        {shareables.length < 1 && (
                                          <div>
                                            <div className="w-full ta-center color-navy-500 fw-extra-bold fs-xl mb-4">
                                              <span>You have no saved designs yet!</span>
                                            </div>
                                            {renderStartDesignTopProducts(onStartDesignClick)}
                                          </div>
                                        )}
                                        {state.showProductPicker && selectedShareable && (
                                          <AbTest experiment="cart-drawer">
                                            <AbVariation variation={true}>
                                              <QueryProducts
                                                query={QUERY_PRODUCTS_BY_ID}
                                                variables={{ ids: productIds }}
                                              >
                                                {({
                                                  error: productsByIdError,
                                                  loading: productsByIdLoading,
                                                  data: productsByIdData,
                                                }) => {
                                                  const products = _.compact(
                                                    productsByIdData && productsByIdData.productsById
                                                  );
                                                  if (products && products.length) {
                                                    return (
                                                      <CartDrawer
                                                        show={true}
                                                        cart={cart}
                                                        products={products}
                                                        onClose={() => closeProductQuantityPicker()}
                                                        selected={
                                                          selectedShareable
                                                            ? {
                                                                design: selectedShareable.products[0].design!,
                                                                productId: selectedShareable.products[0].productId,
                                                                color: selectedShareable.products[0].colors[0].name,
                                                                sizeQuantities: [],
                                                                onAddToCart: () => {},
                                                              }
                                                            : undefined
                                                        }
                                                      />
                                                    );
                                                  }
                                                  return null;
                                                }}
                                              </QueryProducts>
                                            </AbVariation>
                                            <AbVariation variation={false}>
                                              <ProductsQuantitySelector
                                                currentUser={currentUser}
                                                designId={selectedDesign}
                                                productId={selectedProduct}
                                                onBackButtonClick={() => {
                                                  closeProductQuantityPicker();
                                                }}
                                                show={state.showProductPicker}
                                                onCartItemRemoved={() => {}}
                                                onColorSelected={() => {}}
                                                onAddToCart={() => {
                                                  onAddToCartClick && onAddToCartClick(cart.id);
                                                }}
                                                colorNames={getSelectedShareableSelectedColor(
                                                  data.shareables,
                                                  shareCode
                                                )}
                                              />
                                            </AbVariation>
                                          </AbTest>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                );
                              }}
                            </Mutation>
                          );
                        }}
                      </Query>
                    )}
                  </div>
                );
              }}
            </Query>
          );
        }}
      </TypedSubscribe>
    );
  }
}

const renderStartDesignTopProducts = (onStartDesignClick: (productId: any, colorName: string) => void) => {
  return (
    <Query query={PRODUCTS_QUERY}>
      {({ loading, error, data: { productInfo } }: any) => {
        return (
          <div>
            <span className="fs-xl fw-bold float-left color-navy mb-2">Start Designing With These Products</span>
            {renderLoadingOrError(loading, error)}
            {!loading && !error && renderProducts(productInfo.items, onStartDesignClick)}
          </div>
        );
      }}
    </Query>
  );
};

const renderShareModal = (savedDesignsContainer: SavedDesignsState, currentUser: User) => {
  const { state, addShareEmail, removeShareEmail, onEmailChange, toogleShareModal } = savedDesignsContainer;
  return (
    <SaveDesignModal
      currentUser={currentUser}
      userId={currentUser.id}
      designName={state.designName}
      email={state.shareEmail}
      password={''}
      designs={''}
      products={''}
      loggedIn={true}
      onFieldChange={onEmailChange}
      onSaved={() => null}
      duplicate={null}
      onDuplicate={() => {}}
      onCancel={() => {
        toogleShareModal();
      }}
      selectedProduct={{}}
      selectedItem={{}}
      selectedTemplate={null}
      selectedColor={''}
      scene={'share'}
      onContinue={() => {
        toogleShareModal();
      }}
      onChangeScene={() => {
        toogleShareModal();
      }}
      shareUrl={url('app', routes.app.share, { code: state.shareCode })}
      shareEmail={state.shareEmail}
      shareEmails={state.shareEmails}
      onAddShareEmail={addShareEmail}
      onRemoveShareEmail={removeShareEmail}
      onEmailInUse={() => null}
      emailInUse={null}
      onFieldError={() => null}
      fieldErrors={[]}
      cart={false}
      shareCode={state.shareCode}
    />
  );
};

const renderProducts = (
  productItems: ProductItem[],
  onStartDesignClick: (productId: any, colorName: string) => void
) => {
  const products = mapProducts(productItems);
  return (
    <div className="w-full mt-2 flex flex-wrap items-end md:flex-no-wrap px-p5">
      {products.map((product: ProductPropsWithColor) => {
        return <div className="w-1/2 md:w-1/4 px-p5 py-1">{renderProductCard(product, onStartDesignClick)}</div>;
      })}
    </div>
  );
};

interface ProductItem {
  name: string;
  image: {
    url: string;
  };
  colors: ProductColor[];
  id: string;
}

const mapProducts = (items: ProductItem[]): ProductPropsWithColor[] => {
  return items.map((product: Product) => {
    const {
      name,
      image: { url },
      colors,
      sides,
      id,
    } = product;

    let productImage = url;
    let mockupTemplateIdentifier: string | undefined;

    if (product.detailTemplates && product.detailTemplates.length > 0) {
      const detailTemplate = product.detailTemplates.find((detailTemplate: DesignTemplate) => {
        return detailTemplate.side.toLowerCase() === DEFAULT_MOCKUP_SIDE;
      });

      if (detailTemplate) {
        mockupTemplateIdentifier = detailTemplate.id;
      }
    }

    const blankColor = colors && colors.length > 0 ? colors[0].hex : 'ffffff';

    if (mockupTemplateIdentifier) {
      productImage = getMockupBlankUrl(blankColor, mockupTemplateIdentifier);
    }

    const color = colors[0].name;
    const filteredColorsForProduct: ProductColor[] = product.colors;

    const lowestStartingPriceForFilteredColors: number = filteredColorsForProduct.reduce(
      (prev: number, current: ProductColor): number => {
        const currentPrice: number = current.startingPrice;
        if (currentPrice < prev) {
          return currentPrice;
        }
        return prev;
      },
      Infinity
    );
    return {
      id,
      key: id,
      sides,
      name,
      image: productImage,
      price: Math.round(lowestStartingPriceForFilteredColors / ITEMS_NUMBER_FOR_STARTING_PRICE),
      colorCount: colors.length,
      pathname: '',
      color,
    };
  });
};

const renderProductCard = (
  productProps: ProductPropsWithColor,
  onStartDesignClick: (product: any, colorName: string) => void
) => {
  const { id, image, name, price, colorCount, color } = productProps;
  return (
    <div
      onClick={(event) => {
        event.preventDefault();
        onStartDesignClick(productProps, color);
      }}
    >
      <div>
        <ProductCard
          id={id}
          image={image}
          name={name}
          price={price}
          colorCount={colorCount}
          pathname={''}
          search={''}
        />
      </div>
      <Button className="w-full mt-p5">Start Designing</Button>
    </div>
  );
};
