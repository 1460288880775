import * as React from 'react';
import * as Sentry from '@sentry/browser';
import _ from 'lodash';
import { routes, url } from 'inkp-routes';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import mockupRoutes from 'inkp-mockup-sdk/routes';
import { stringifyProductItemId, parseProductItemId } from 'inkp-product-sdk';
import {
  DesignTemplate,
  Product,
  ProductColor,
  ProductImage,
  ProductSize,
  Query as ProductQueryResults,
  QueryproductsByIdArgs,
} from 'inkp-product-sdk/types.g';
import { Query as OrderQueryResults, Cart, CartItem, CartItemInput, PRINT_TYPES_ENUM } from 'inkp-order-sdk/types.g';
import { Query as UserQueryResults, User } from 'inkp-user-sdk/types.g';
import { DESIGN_SIDE_ENUM, DesignSide, Shareable } from 'inkp-design-sdk/types.g';
import { DESIGN_SIDE_PRINT_SIDE_MAP } from 'inkp-design-sdk/design';
import Loading from 'inkp-components/dist/Components/Loading';
import Alert from 'inkp-components/dist/Components/Alert';
import RightSidebar from 'inkp-components/dist/Components/RightSidebar';

import { TemporalCartItem } from '../../interfaces/CartProduct';

import ProductsQuantitySelector from './ProductsQuantitySelector';
import { getMockups, calculateCartProductQuantity } from '../../util/Checkout';

// GraphQL
// import { PRODUCT_FRAGMENT } from '../../graphql/products';
import { QueryCurrentUser, QUERY_CURRENT_USER } from '../../graphql/users';
import { QUERY_DESIGN_BY_ID, QueryDesignById } from '../../graphql/designs';
import { DesignCartState, DesignCartProduct, DesignCartDesign } from '../../states/global/designCart';

import { SaveDesignProgress } from '../../components/SaveDesignProgress';

const DEFAULT_PRODUCT_SIZE: string = 'med';
const DEFAULT_COLOR_NAME: string = 'white';
const MOCKUP_SIZE: string = 'medium';
const FRONT_IMAGE_LABEL: string = 'front';

const CART_QUERY = gql`
  fragment cartFields on Cart {
    id
    userId
    status
    coupons {
      code
    }
    items {
      product {
        productItemId
        quantity
        mockupUrl
        mockups {
          side
          mockupUrl
        }
      }
      designId
    }
  }

  query CartByUser {
    cartByUser {
      ...cartFields
    }
  }
`;

export const PRODUCT_FRAGMENT = gql`
  fragment ProductFragment on Product {
    id
    additionalImages {
      label
      url
    }
    brand
    categories {
      name
      scope
    }
    categoryIdentifier
    colors {
      class
      enabled
      heather
      hex
      images {
        label
        url
      }
      inStock
      name
      printTypes {
        SCREENPRINT
        DTG
      }
      productId
      sizes
      sizesObjects {
        id
        inStock
        name
        price
        speed
      }
      startingPrice
    }
    comments
    createdAt
    deletedAt
    description
    designTemplates {
      id
      side
    }
    detailTemplates {
      id
      side
    }
    enabled
    image {
      label
      url
    }
    inStock
    material
    name
    printTypes {
      SCREENPRINT
      DTG
    }
    rank
    relatedLabels
    sides {
      name
      printZones
    }
    sla
    status
    styleId
    tags {
      material
      collar
      brand
      sleeve
      type
      gender
      weave
      feature
    }
    type
    updatedAt
  }
`;

const PRODUCT_QUERY = gql`
  query ProductById($id: String!) {
    productById(id: $id) {
      ...ProductFragment
    }
  }

  ${PRODUCT_FRAGMENT}
`;

const PRODUCTS_QUERY = gql`
  query ProductsById($ids: [String!]!) {
    productsById(ids: $ids) {
      ...ProductFragment
    }
  }

  ${PRODUCT_FRAGMENT}
`;

const CURRENT_USER_QUERY = gql`
  query CurrentUser {
    currentUser {
      id
    }
  }
`;

class CurrentUserQuery extends Query<UserQueryResults> {}
class CartQuery extends Query<OrderQueryResults> {}
class ProductsQuery extends Query<ProductQueryResults, QueryproductsByIdArgs> {}

interface Props {
  /**
   * Array of colorNames (color name with spaces) that the user selected for the current product
   * @type {string}
   * @default ['white']
   */
  colorNames?: string[];

  /**
   * Design Id to use to filter the cartProducts
   * @type {string}
   */
  designId: string;

  /**
   * Produt Id to use to filter the cartProducts
   * @type {string}
   */
  productId: string;

  /**
   * Whether or not to display this component
   * in the future this will handle the transitions
   * @type {boolean}
   * @default false
   */
  show?: boolean;

  /**
   * initial sizesQuantities
   * @type {{ name: string, qty: number }}
   */
  sizesQuantities?: { name: string; qty: number }[];

  /**
   * On back button clicked handler
   * @type {function}
   * @required
   */
  onBackButtonClick: (ev: React.MouseEvent<HTMLButtonElement>) => void;

  /**
   * On CartItem button click handler
   * @type {function}
   * @required
   */
  onCartItemRemoved: (value: string) => void;

  /**
   * On color tile selected
   * @type {function}
   * @required
   */
  onColorSelected: (value: string) => void;

  /**
   * Optional function for redirect to cart page
   * @type {function}
   * @required
   */
  onAddToCart?: () => void;

  /**
   * Current user object
   * @type {User}
   */
  currentUser: User;

  /**
   * All the next properties are needed for the SaveDesignProgress component
   */

  /**
   * When calling this component from the DesignTools, we will have access to a designName
   * or if the user has already saved the design we will have this as well
   * @type {String}
   */
  designName?: string;

  /**
   * Product being used in the DesignTools
   * @type {Product}
   */
  selectedProduct?: Product;

  /**
   * Product color selected for the Product in the DesignTools
   * @type {ProductColor}
   */
  selectedColor?: ProductColor;

  /**
   * DesignTemplate for the current Product
   * @type {DesignTemplate}
   */
  selectedTemplate?: DesignTemplate;

  /**
   * Current designState. This will only be available if this component is called from the Design Tools
   * @type {DesignCartState}
   */
  designState?: DesignCartState;

  /**
   * On saved design handler
   * @param {Sharable} shareable - Sharable object obtained from the createShareable/updateShareable mutations
   * @param {string} mockupUrl - The URL to the mockup for the saved design.
   */
  onSavedDesign?: (shareable: Shareable, mockupUrl: string) => void;
}

interface State {}

class QuantitySelector extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      designName,
      designState,
      selectedProduct,
      selectedColor,
      selectedTemplate,
      onSavedDesign,
      currentUser,

      productId,
      designId,
      sizesQuantities,
      onBackButtonClick,
      onCartItemRemoved,
      onColorSelected,
      onAddToCart,
    } = this.props;

    let colorNames: string[];
    if (!this.props.colorNames) {
      colorNames = [DEFAULT_COLOR_NAME];
    } else {
      colorNames = this.props.colorNames;
    }

    colorNames = colorNames
      .filter((colorName: string) => !!colorName)
      .map((colorName: string) => colorName.toLowerCase());

    const currentCartProductId: string = JSON.stringify({
      productId,
      designId,
    });

    const renderSaveDesignProgress = designState && (designId === null || !designState.saved);

    return (
      <RightSidebar
        id="desktop-quantity-selector"
        show={true}
        title={'Quote'}
        showHeader={true}
        onBackButtonClick={onBackButtonClick}
        onOverlayClick={onBackButtonClick}
        overlay={true}
        cartQuantity={1}
        links={{ checkout: routes.app.checkout.cart, base: routes.app.base }}
      >
        {renderSaveDesignProgress ? (
          <SaveDesignProgress
            designState={designState!}
            designName={designName!}
            selectedProduct={selectedProduct!}
            selectedColor={selectedColor!}
            selectedTemplate={selectedTemplate!}
            onSaved={(shareable, mockupUrl) => {
              if (onSavedDesign) {
                onSavedDesign(shareable, mockupUrl);
              }
            }}
          />
        ) : (
          <QueryDesignById query={QUERY_DESIGN_BY_ID} variables={{ id: designId }}>
            {({ error: designByIdError, loading: designByIdLoading, data: designByIdData }) => {
              return (
                <CartQuery query={CART_QUERY}>
                  {({ data: cartQueryData, loading: cartQueryLoading, error: cartQueryError }) => {
                    const loading = designByIdLoading || cartQueryLoading;
                    const error = designByIdError || cartQueryError;
                    const design = designByIdData && designByIdData.designById;
                    const cart = cartQueryData && cartQueryData.cartByUser;

                    if (loading) {
                      return (
                        <div className=" w-full h-full flex justify-center items-center p-absolute pin bgc-white-70">
                          <Loading size="large" />
                        </div>
                      );
                    }

                    if (error || !design || !cart) {
                      designByIdError && Sentry.captureException(designByIdError);
                      cartQueryError && Sentry.captureException(cartQueryError);
                      !design &&
                        Sentry.captureException(new Error(`Couldn't fetch Design for given designId: ${designId}`));
                      !cart && Sentry.captureException(new Error(`Couldn't fetch Cart for user: ${currentUser.id}`));
                      // TODO - Add Error state probably something that allows the user to retry.
                      return (
                        <div className="error-overlay w-full h-full flex justify-center items-center">
                          <Alert
                            alerts={[
                              {
                                type: 'error',
                                title: 'Checkout error',
                                content:
                                  'Oops! Something went wrong. We are very sorry. Please try again later or contact us.',
                              },
                            ]}
                          />
                        </div>
                      );
                    }

                    const designSides: DesignSide[] = design.sides;
                    const designSideToDisplay: DesignSide | undefined = designSides.find((designSide) => {
                      return designSide.shapes.length > 0;
                    });
                    const designSideToDisplayKey: string = designSideToDisplay
                      ? DESIGN_SIDE_ENUM[designSideToDisplay.name]
                      : DESIGN_SIDE_ENUM.FRONT;

                    const cartId = cart.id;
                    let productIds = cart.items.map((cartItem: CartItem) => {
                      const { product } = cartItem;
                      const { productId } = parseProductItemId(product.productItemId);
                      return productId;
                    });
                    productIds = productIds.concat([productId]);
                    productIds = _.uniq(productIds);
                    const cartQuantity = calculateCartProductQuantity(cart);

                    return (
                      <ProductsQuery query={PRODUCTS_QUERY} variables={{ ids: productIds }}>
                        {({ error: productsQueryError, loading: productsQueryLoading, data: productsQueryData }) => {
                          if (productsQueryError) {
                            console.error(productsQueryError);
                            return (
                              <div className="error-overlay w-full h-full flex justify-center items-center">
                                <Alert
                                  alerts={[
                                    {
                                      type: 'error',
                                      title: 'Checkout error',
                                      content:
                                        'Oops! Something went wrong. We are very sorry. Please try again later or contact us.',
                                    },
                                  ]}
                                />
                              </div>
                            );
                          }
                          if (productsQueryLoading) {
                            return (
                              <div className=" w-full h-full flex justify-center items-center p-absolute pin bgc-white-70">
                                <Loading size="large" />
                              </div>
                            );
                          }

                          if (productsQueryData && productsQueryData.productsById) {
                            const { productsById: products } = productsQueryData;
                            let cartItems: TemporalCartItem[] = cart.items.map((cartItem: CartItem) => {
                              const {
                                designId,
                                product: { productItemId },
                              } = cartItem;
                              const { productId } = parseProductItemId(productItemId);
                              const product: Product | undefined = products.find((product: Product) => {
                                return product.id === productId;
                              });
                              return {
                                designId,
                                product: {
                                  ...cartItem.product,
                                  product,
                                },
                              };
                            });

                            const currentProduct: Product | undefined = products.find((product: Product) => {
                              return product.id === productId;
                            }) as Product;

                            const currentProductColors: ProductColor[] = currentProduct.colors;
                            let selectedProductColors: ProductColor[] | undefined = currentProductColors.filter(
                              (productColor: ProductColor) => {
                                const colorName: string = productColor.name.toLowerCase();
                                return colorNames.indexOf(colorName) !== -1;
                              }
                            );
                            if (selectedProductColors.length === 0) {
                              selectedProductColors = [currentProductColors[0]];
                            }
                            const firstSelectedProductColor: ProductColor = selectedProductColors[0];
                            const firstAvailableSizeForSelectedProductColor: ProductSize | undefined =
                              firstSelectedProductColor.sizesObjects &&
                              firstSelectedProductColor.sizesObjects.find((sizeObject: ProductSize) => {
                                return sizeObject.inStock;
                              });

                            const firstAvailableSize: string = firstAvailableSizeForSelectedProductColor
                              ? firstAvailableSizeForSelectedProductColor.name
                              : DEFAULT_PRODUCT_SIZE;

                            const designTemplate: DesignTemplate | undefined = currentProduct.designTemplates.find(
                              (designTemplate) => {
                                return designTemplate.side === designSideToDisplayKey;
                              }
                            );
                            const templateIdentifier: string = designTemplate ? designTemplate.id : '';
                            const side: string = designSideToDisplayKey;
                            const size: string = MOCKUP_SIZE;

                            // if cartItems is empty we want at least one cartItem for the current Products
                            if (cartItems.length === 0) {
                              // one item per selected color
                              const cartItemsForCurrentProduct: TemporalCartItem[] = selectedProductColors.map(
                                (productColor: ProductColor): TemporalCartItem => {
                                  const productItemId: string = stringifyProductItemId(
                                    currentProduct.id,
                                    productColor.name,
                                    firstAvailableSize
                                  );
                                  const color: string = productColor.hex;
                                  let mockupUrl: string = '';
                                  if (templateIdentifier) {
                                    mockupUrl = url('mockup', mockupRoutes.mockup, {
                                      designId,
                                      templateIdentifier,
                                      color,
                                      side,
                                      size,
                                    });
                                    console.log('[DEBUG] mockupUrl: ', mockupUrl);
                                  } else {
                                    if (!productColor.images || productColor.images.length === 0) {
                                      throw new Error(
                                        `No images where found for the given color: ${productColor.name}`
                                      );
                                    }
                                    let image: ProductImage | undefined;
                                    if (productColor.images && productColor.images.length > 0) {
                                      image = productColor.images.find((image: any) => {
                                        return image.label.toLowerCase() === FRONT_IMAGE_LABEL;
                                      });
                                      if (!image) {
                                        image = productColor.images[0];
                                      }
                                      mockupUrl = image.url;
                                    }
                                  }

                                  const mockups = getMockups({
                                    product: currentProduct,
                                    design,
                                    colorHex: color,
                                    mockupSize: MOCKUP_SIZE,
                                  });
                                  const cartItemForCurrentProduct: TemporalCartItem = {
                                    designId,
                                    product: {
                                      productItemId,
                                      quantity: 0,
                                      product: currentProduct,
                                      mockupUrl,
                                      mockups,
                                    },
                                  };
                                  return cartItemForCurrentProduct;
                                }
                              );

                              cartItems = [...cartItemsForCurrentProduct];
                            } else {
                              const colorsRegex: string = colorNames
                                .map((colorName: string) => colorName.replace(/\s/gi, '\\-'))
                                .join('|');
                              const cartItemsRegexForCurrentProductAndColors: RegExp = new RegExp(
                                `${currentProduct.id}_(${colorsRegex})`
                              );
                              const otherProductsCartItems: TemporalCartItem[] = cartItems.filter(
                                (cartItem: CartItem) => {
                                  return (
                                    cartItem.designId !== designId ||
                                    cartItem.product.productItemId.match(cartItemsRegexForCurrentProductAndColors) ===
                                      null
                                  );
                                }
                              );
                              // filter the cartitems that match the current designId + productId
                              // and filter out cartitems for any color that no longer exists in the
                              // incoming array of colors
                              const filteredCartItemsForCurrentProduct: TemporalCartItem[] = cartItems.filter(
                                (cartItem: CartItem) => {
                                  return (
                                    cartItem.designId === designId &&
                                    cartItem.product.productItemId.match(cartItemsRegexForCurrentProductAndColors)
                                  );
                                }
                              );
                              const colorsForFilteredCartItems: string[] = [];
                              filteredCartItemsForCurrentProduct.forEach(
                                (filteredCartItemForCurrentProduct: CartItem) => {
                                  const { color } = parseProductItemId(
                                    filteredCartItemForCurrentProduct.product.productItemId
                                  );
                                  return (
                                    colorsForFilteredCartItems.indexOf(color) === -1 &&
                                    colorsForFilteredCartItems.push(color)
                                  );
                                }
                              );
                              // check for any new color (new cartitem) and add it to the current ones
                              const newColorsToAdd: string[] = colorNames.filter((colorName: string) => {
                                return colorsForFilteredCartItems.indexOf(colorName) === -1;
                              });
                              // create new cart items for each new color
                              const cartItemsForNewAddedColors: TemporalCartItem[] = newColorsToAdd.map(
                                (newColor: string) => {
                                  const productColor: ProductColor | undefined = currentProduct.colors.find(
                                    (currentProductColor: ProductColor) => {
                                      return currentProductColor.name.toLowerCase() === newColor;
                                    }
                                  );
                                  if (!productColor) {
                                    throw new Error("Couldn't found new color for the current Product's colors.");
                                  }
                                  const firstAvailableSizeForSelectedProductColor: ProductSize | undefined =
                                    productColor.sizesObjects &&
                                    productColor.sizesObjects.find((sizeObject: ProductSize) => {
                                      return sizeObject.inStock;
                                    });
                                  const firstAvailableSize: string = firstAvailableSizeForSelectedProductColor
                                    ? firstAvailableSizeForSelectedProductColor.name
                                    : DEFAULT_PRODUCT_SIZE;

                                  const productItemId: string = stringifyProductItemId(
                                    currentProduct.id,
                                    productColor.name,
                                    firstAvailableSize
                                  );

                                  const color: string = productColor.hex;
                                  let mockupUrl: string = '';
                                  if (templateIdentifier) {
                                    mockupUrl = url('mockup', mockupRoutes.mockup, {
                                      designId,
                                      templateIdentifier,
                                      color,
                                      side,
                                      size,
                                    });
                                    console.log('[DEBUG] mockupUrl: ', mockupUrl);
                                  } else {
                                    if (!productColor.images || productColor.images.length === 0) {
                                      throw new Error(
                                        `No images where found for the given color: ${productColor.name}`
                                      );
                                    }
                                    let image: ProductImage | undefined;
                                    if (productColor.images && productColor.images.length > 0) {
                                      image = productColor.images.find((image: any) => {
                                        return image.label.toLowerCase() === FRONT_IMAGE_LABEL;
                                      });
                                      if (!image) {
                                        image = productColor.images[0];
                                      }
                                      mockupUrl = image.url;
                                    }
                                  }

                                  const mockups = getMockups({
                                    product: currentProduct,
                                    design,
                                    colorHex: color,
                                    mockupSize: MOCKUP_SIZE,
                                  });
                                  const cartItemForNewColor: TemporalCartItem = {
                                    designId,
                                    product: {
                                      productItemId,
                                      quantity: 0,
                                      product: currentProduct,
                                      mockupUrl,
                                      mockups,
                                    },
                                  };
                                  return cartItemForNewColor;
                                }
                              );
                              // cartItems will be the filtered cart items for the current product
                              // for given colors and the new cart items created for any new color.
                              cartItems = [
                                ...otherProductsCartItems,
                                ...filteredCartItemsForCurrentProduct,
                                ...cartItemsForNewAddedColors,
                              ];
                            }

                            return (
                              <ProductsQuantitySelector
                                cartId={cartId}
                                cartProductId={currentCartProductId}
                                cartItems={cartItems}
                                currentProduct={currentProduct}
                                designId={designId}
                                sizesQuantities={sizesQuantities}
                                onColorSelected={onColorSelected}
                                onDeleteButtonClicked={onCartItemRemoved}
                                onAddToCart={onAddToCart}
                              />
                            );
                          }
                        }}
                      </ProductsQuery>
                    );
                  }}
                </CartQuery>
              );
            }}
          </QueryDesignById>
        )}
      </RightSidebar>
    );
  }
}

export default QuantitySelector;
