import _ from 'lodash';
import React, { Children } from 'react';
import { Ab } from 'inkp-user-sdk/types.g';

import { QueryCurrentUser, QUERY_CURRENT_USER } from '../../../graphql/users';

interface Props {
  /**
   * experiment name
   */
  experiment: string;

  /**
   * children can be array of AbVariation or render function
   */
  children: ((variation: string | boolean) => React.ReactNode) | React.ReactNode;
}

function getVariant(abTest: Ab): string | boolean {
  const value = abTest.value;
  if (value === 'true') return true;
  if (value === 'false') return false;
  return value;
}

export default function AbTest(props: Props) {
  const { experiment, children } = props;

  if (!experiment || !children) return null;

  return (
    <QueryCurrentUser query={QUERY_CURRENT_USER}>
      {({ error: userError, loading: userLoading, data: userData }) => {
        if (userLoading || userError || !userData || !userData.currentUser) {
          return null;
        }
        const ab = userData.currentUser.ab;
        const abTest = _.find(ab, { name: experiment });
        if (!abTest) return null;
        
        const variant = getVariant(abTest);
        
        // render functions
        if (typeof children === 'function') return (children as Function).apply(null, [variant]);

        // render components
        const newChildren = React.Children.map(children, (child: JSX.Element) => {
          if (_.get(child, 'props.variation') === variant) return child;
          return null;
        });
        return newChildren;
      }}
    </QueryCurrentUser>
  );
}

export const AbVariation: React.FunctionComponent<{
  variation: string | boolean;
  children: React.ReactNode;
}> = (props) => {
  return <>{props.children}</>;
}