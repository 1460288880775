import * as React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import { Product, ProductColor } from 'inkp-product-sdk';
import ProductCard, { Props as Productprops } from 'inkp-components/dist/Components/ProductCard';
import Button from 'inkp-components/dist/Components/Button';

interface Props {
  textDisplay: string;
  onStartDesignClick: (productId: any, color: string) => void;
}

// Interfaces
interface ProductItem {
  name: string;
  image: {
    url: string;
  };
  colors: ProductColor[];
  id: string;
}

interface ProductPropsWithColor extends Productprops {
  color: string;
}

const ITEMS_NUMBER_FOR_STARTING_PRICE = 50;

const PRODUCTS_QUERY = gql`
  query GetProducts {
    productInfo: filterProducts(
      sortBy: {
        field: "rank"
        order: 1
      }
      page: 1
      perPage: 4
      categories: {
        name: "t-shirts"
        scope: "type"
      }
    ) {
      items {
        id
        name
        sides {
          name
          printZonesObjects {
            id
          }
        }
        colors {
          name
          hex
          startingPrice
          class
          sizes
        }
        image {
          label
          url
        }
      }
    }
  }
`;

export default class YouDontHave extends React.Component<Props> {
  public static defaultProps: Props = {
    textDisplay: 'You have no saved designs yet!',
    onStartDesignClick: (productId: any, color: string) => {},
  };

  renderProducts = (productItems: ProductItem[], onStartDesignClick: (productId: any, colorName: string) => void) => {
    const products = this.mapProducts(productItems);
    return (
      <div className="w-full mt-2 flex flex-wrap md:flex-no-wrap">
        {products.map((product: ProductPropsWithColor) => {
          return <div className="w-1/2 md:w-1/4 px-p5  pb-1 md:py-0">{this.renderProductCard(product, onStartDesignClick)}</div>;
        })}
      </div>
    );
  };
  renderLoadingOrError = (loading: boolean, error: boolean) => {
    if (loading) {
      return <div>Loading Design</div>;
    }
    if (error) {
      return <div>Error</div>;
    }
    return null;
  };

  mapProducts = (items: ProductItem[]): ProductPropsWithColor[] => {
    return items.map((product: Product) => {
      const {
        name,
        image: { url },
        colors,
        sides,
        id,
      } = product;

      const color = colors[0].name;
      const filteredColorsForProduct: ProductColor[] = product.colors;

      const lowestStartingPriceForFilteredColors: number = filteredColorsForProduct.reduce(
        (prev: number, current: ProductColor): number => {
          const currentPrice: number = current.startingPrice;
          if (currentPrice < prev) {
            return currentPrice;
          }
          return prev;
        },
        Infinity
      );
      return {
        id,
        key: id,
        sides,
        name,
        image: url,
        price: Math.round(lowestStartingPriceForFilteredColors / ITEMS_NUMBER_FOR_STARTING_PRICE),
        colorCount: colors.length,
        pathname: '',
        color,
      };
    });
  };

  renderProductCard = (
    productProps: ProductPropsWithColor,
    onStartDesignClick: (product: any, colorName: string) => void
  ) => {
    const { id, image, name, price, colorCount, color } = productProps;
    return (
      <div
        onClick={(event) => {
          event.preventDefault();
          onStartDesignClick(productProps, color);
        }}
      >
        <div>
          <ProductCard
            id={id}
            image={image}
            name={name}
            price={price}
            colorCount={colorCount}
            pathname={''}
            search={''}
          />
        </div>
        <Button className="w-full mt-p5">Start Designing</Button>
      </div>
    );
  };

  renderStartDesignTopProducts = (onStartDesignClick: (productId: any, colorName: string) => void) => {
    return (
      <Query query={PRODUCTS_QUERY}>
        {({ loading, error, data: { productInfo } }: any) => {
          return (
            <div className="px-p5 md:px-0">
              <span className="fw-extra-bold md:fs-xl md:fw-bold float-left color-navy mb-2 px-p5 md:px-0">Start Designing With These Products</span>
              {this.renderLoadingOrError(loading, error)}
              {!loading && !error && this.renderProducts(productInfo.items, onStartDesignClick)}
            </div>
          );
        }}
      </Query>
    );
  };

  render() {
    const { textDisplay, onStartDesignClick } = this.props;
    return (
      <React.Fragment>
        <div className="w-full py-1p5 ta-center color-navy-500 fw-extra-bold fs-xl my-1p5">
          <span>{textDisplay}</span>
        </div>
        {this.renderStartDesignTopProducts(onStartDesignClick)}
      </React.Fragment>
    );
  }
}
