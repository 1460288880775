import * as React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { withRouter, RouteComponentProps } from 'react-router';
import { routes, url } from 'inkp-routes';
import { Query, Mutation, MutationFn } from 'react-apollo';
import { DataProxy } from 'apollo-cache';
import gql from 'graphql-tag';
import Img from 'react-image';

import mockupRoutes from 'inkp-mockup-sdk/routes';
import {
  CartItem,
  CartItemInput,
  CartShippingInput,
  CompleteQuoteItemsWithoutPrintType,
  Mutation as OrderMutationResults,
  MutationupdateCartArgs,
  Query as QueryOrderResults,
  QueryrequestCartQuoteArgs,
  QueryrequestCompleteQuoteWithoutPrintTypeArgs,
  RequestCompleteQuoteOutput,
  SHIPPING_SPEED_ENUM,
} from 'inkp-order-sdk/types.g';
import { getCartProductTotalQuantity } from 'inkp-order-sdk/order';
import { Product, ProductColor, ProductImage, ProductSize, DesignTemplate } from 'inkp-product-sdk/types.g';
import { PRODUCT_CATEGORY_SCOPES } from 'inkp-product-sdk/constants';
import { prettyProductSize } from 'inkp-product-sdk';
import {
  Query as DesignQueryResults,
  QuerydesignByIdArgs,
  DesignSide,
  DESIGN_SIDE_ENUM,
} from 'inkp-design-sdk/types.g';
import { CartProduct, SizesQuantities, TemporalCartItem } from '../../interfaces/CartProduct';

// Components
import Alert from 'inkp-components/dist/Components/Alert';
import Button from 'inkp-components/dist/Components/Button';
import Modal from 'inkp-components/dist/Components/Modal';
import Loading from 'inkp-components/dist/Components/Loading';
import TextArea from 'inkp-components/dist/Components/TextArea';

// Helpers
import GTM from '../../util/gtm';
import { GTMTypes, GTMEvents } from '../../interfaces/GTM';
import {
  CartItemsToCartProducts,
  CartProductsToCartItems,
  GetProductsHashFromCartItems,
  ProductsHashToProductsArray,
  designInksDisplay,
  GetDeliverByDate,
} from '../../util/Product';
import {
  getCartProductErrors,
  getCartProductWarnings,
  updateCurrentCart,
  formatPrice,
  CHECKOUT_CART_FRAGMENT,
  QUERY_REQUEST_CART_QUOTE,
  STRAIGHTEN_SVG,
  QUERY_REQUEST_COMPLETE_QUOTE_WITHOUT_PRINT_TYPE,
} from '../../util/Checkout';
import ProductQuantitySelector from '../ProductQuantitySelector';
import SizeGuide from '../SizeGuide';
import { SIZES_BY_BRAND } from '../SizeGuide/constants';

const MOCKUP_SIZE: string = 'medium';

const UPDATE_CART_MUTATION = gql`
  mutation UpdateCart($id: ID!, $data: UpdatableCartFields!) {
    updateCart(id: $id, data: $data) {
      ...CheckoutCartFragment
    }
  }
  ${CHECKOUT_CART_FRAGMENT}
`;

const QUERY_DESIGN_BY_ID = gql`
  query GetDesignById($id: String!) {
    designById(id: $id) {
      sides {
        name
        shapes {
          id
        }
        artwork {
          fullColor
          colors {
            name
            hex
          }
        }
      }
    }
  }
`;

class UpdateCartMutation extends Mutation<OrderMutationResults, MutationupdateCartArgs> {}
class QueryDesignById extends Query<DesignQueryResults, QuerydesignByIdArgs> {}
class QueryRequestCompleteQuote extends Query<QueryOrderResults, QueryrequestCompleteQuoteWithoutPrintTypeArgs> {}
class QueryRequestCartQuote extends Query<QueryOrderResults, QueryrequestCartQuoteArgs> {}

interface Props extends RouteComponentProps {
  /**
   * Id of cart
   * @type {string}
   */
  cartId: string;

  /**
   * Current items in the cart (at least we will have one)
   * @type {TemporalCartItem[]}
   */
  cartItems: TemporalCartItem[];

  /**
   * Id for the current CartProduct we are working on ${productId}_${designId}
   * @type {string}
   */
  cartProductId: string;

  /**
   * Product of the current CartProducts that we are working on
   * @type {Product}
   */
  currentProduct: Product;

  /**
   * Id of the current design.
   * @type {string}
   * @required
   */
  designId: string;

  /**
   * initial sizesQuantities
   * @type {{ name: string, qty: number }}
   */
  sizesQuantities?: { name: string; qty: number }[];

  /**
   * On color tile selected
   * @type {function}
   * @required
   */
  onColorSelected: (value: string) => void;

  /**
   * On delete button click handler
   * @type {function}
   * @required
   */
  onDeleteButtonClicked: (value: string) => void;

  /**
   * Optional function for redirect to cart page
   * @type {function}
   * @required
   */
  onAddToCart?: () => void;
}

interface State {
  cartProducts: CartProduct[]; // CartProducts for the current Product
  productColors: ProductColor[]; // Set of colors for the current Product
  showMoreColors: boolean;
  showSizeGuide: boolean;
  showSizeGuideSizes: string[];
  debouncedQuoteItems: CompleteQuoteItemsWithoutPrintType[];
  quoteSubtotal: number;
  unitPrice: number;
  computedProductQuantity: number;
}

const DEBOUNCE = 200;

class ProductsQuantitySelector extends React.Component<Props, State> {
  public state: State;

  constructor(props: Props) {
    super(props);
    const { cartItems, cartProductId, currentProduct, sizesQuantities } = props;
    const currentCartProduct: any = JSON.parse(cartProductId);
    const productsHashOfCartItems: { [id: string]: Product } = GetProductsHashFromCartItems(cartItems);
    const products: Product[] = ProductsHashToProductsArray(productsHashOfCartItems);
    const cartProducts: CartProduct[] = CartItemsToCartProducts(cartItems, products);
    let currentCartProducts = cartProducts.filter((cartProduct: CartProduct) => {
      return (
        cartProduct.designId === currentCartProduct.designId && cartProduct.product.id === currentCartProduct.productId
      );
    });
    if (sizesQuantities) {
      const formattedSizesQuantities: CartProduct['sizesQuantities'] = {};
      _.forEach(sizesQuantities, ({ name, qty }: { name: string; qty: number }) => {
        if (!(qty > 0)) return;
        formattedSizesQuantities[name] = qty;
      });
      currentCartProducts = _.map(currentCartProducts, (cartProduct) => ({
        ...cartProduct,
        sizesQuantities: formattedSizesQuantities,
      }));
    }
    const currentProductColors: ProductColor[] = currentProduct.colors;
    this.state = {
      cartProducts: [...currentCartProducts],
      productColors: [...currentProductColors],
      showMoreColors: false,
      showSizeGuide: false,
      showSizeGuideSizes: [],
      debouncedQuoteItems: this.getQuoteItems(currentCartProduct.designId, currentCartProducts),
      quoteSubtotal: 0,
      unitPrice: 0,
      computedProductQuantity: 0,
    };
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    const { cartItems, cartProductId } = props;
    let { showMoreColors } = state;
    const currentCartProduct: any = JSON.parse(cartProductId);

    const productsHashOfCartItems: { [id: string]: Product } = GetProductsHashFromCartItems(cartItems);
    const products: Product[] = ProductsHashToProductsArray(productsHashOfCartItems);
    const cartProducts: CartProduct[] = CartItemsToCartProducts(cartItems, products);
    const newCartProducts = cartProducts.filter((cartProduct: CartProduct) => {
      return (
        cartProduct.designId === currentCartProduct.designId && cartProduct.product.id === currentCartProduct.productId
      );
    });
    const newCartProductsIds: string[] = newCartProducts.map((newCartProduct: CartProduct) => newCartProduct.id);
    const filteredCurrentCartProducts: CartProduct[] = state.cartProducts.filter((currentCartProduct: CartProduct) => {
      return newCartProductsIds.indexOf(currentCartProduct.id) !== -1;
    });
    const filteredCurrentCartProductsIds: string[] = filteredCurrentCartProducts.map(
      (filteredCurrentCartProduct: CartProduct) => {
        return filteredCurrentCartProduct.id;
      }
    );
    const newCartProductsToAdd: CartProduct[] = newCartProducts.filter((newCartProduct: CartProduct) => {
      return filteredCurrentCartProductsIds.indexOf(newCartProduct.id) === -1;
    });

    const updatedCurrentCartProducts: CartProduct[] = [...filteredCurrentCartProducts, ...newCartProductsToAdd];

    if (state.cartProducts.length !== updatedCurrentCartProducts.length && state.showMoreColors) {
      showMoreColors = false;
    }

    return {
      cartProducts: updatedCurrentCartProducts,
      showMoreColors,
    };
  }

  private showMoreColors = () => (ev: React.MouseEvent<HTMLButtonElement>) => {
    return this.setState({
      showMoreColors: true,
    });
  };

  private showSizeGuide = (sizes: string[]) => (ev: React.MouseEvent<HTMLButtonElement>) => {
    return this.setState({
      showSizeGuide: true,
      showSizeGuideSizes: sizes,
    });
  };

  private hideSizeGuide = () => () => {
    return this.setState({
      showSizeGuide: false,
      showSizeGuideSizes: [],
    });
  };

  private hasErrors = (cartProducts: CartProduct[]): boolean => {
    return cartProducts.reduce((hasErrors: boolean, cartProduct) => {
      return hasErrors || getCartProductErrors(cartProduct).length > 0;
    }, false);
  };

  private getQuoteItems = (designId: string, cartProducts: CartProduct[]) => {
    const currentCartItems = CartProductsToCartItems(cartProducts);
    const quoteItems: CompleteQuoteItemsWithoutPrintType[] = currentCartItems.map((cartItem) => {
      return {
        printType: cartItem.printType,
        product: {
          productItemId: cartItem.product.productItemId,
          quantity: cartItem.product.quantity,
        },
        designId,
      };
    });
    return quoteItems;
  };

  private setQuoteItems = (designId: string, cartProducts: CartProduct[]) => {
    return this.setState({ debouncedQuoteItems: this.getQuoteItems(designId, cartProducts) });
  };

  private setDebouncedQuoteItems = _.debounce(this.setQuoteItems, DEBOUNCE);

  private onAddToCartButtonClicked = (updateCart: MutationFn<OrderMutationResults, MutationupdateCartArgs>) => (
    ev: React.MouseEvent<HTMLButtonElement>
  ) => {
    const { productId, designId } = JSON.parse(this.props.cartProductId);
    const cartId: string = this.props.cartId;
    const oldCartItems: TemporalCartItem[] = this.props.cartItems;
    const currentCartProducts: CartProduct[] = this.state.cartProducts;
    const currentCartItems: CartItemInput[] = CartProductsToCartItems(currentCartProducts);
    const filteredOldCartItems: CartItemInput[] = oldCartItems
      .filter((cartItem: CartItem) => {
        return (
          (cartItem.designId !== designId || cartItem.product.productItemId.search(productId) === -1) &&
          cartItem.product.quantity > 0
        );
      })
      .map(({ designId, product, printType }: CartItem) => {
        const { productItemId, quantity, mockupUrl, mockups } = product;
        return {
          designId,
          product: {
            productItemId,
            quantity,
            mockupUrl,
            mockups: _.map(mockups, (mockup) => {
              return { side: mockup.side, mockupUrl: mockup.mockupUrl };
            }),
          },
          printType,
        };
      });
    const newCartItems: CartItemInput[] = [...filteredOldCartItems, ...currentCartItems];
    const SHPPING_DETAILS: CartShippingInput = {
      speed: SHIPPING_SPEED_ENUM.NINE_DAY,
    };
    return updateCart({
      variables: {
        id: cartId,
        data: {
          items: newCartItems,
          shippingDetails: SHPPING_DETAILS,
        },
      },
    });
  };

  private updateSizeQuantity = (cartProduct: CartProduct, size: string, quantity: number): CartProduct => {
    const sizesQuantities: SizesQuantities = {
      ...cartProduct.sizesQuantities,
      [size]: quantity,
    };
    return {
      ...cartProduct,
      sizesQuantities,
    };
  };

  public onQuantityChange = (cartProductId: string) => (size: string, quantity: number) => {
    const index = this.state.cartProducts.findIndex((cartProduct: CartProduct) => cartProduct.id === cartProductId);
    if (index === -1) {
      console.warn(`Couldn't found CartProduct for given cartProductId: ${cartProductId}`);
      return;
    }

    const updatedCartProduct = this.updateSizeQuantity(this.state.cartProducts[index], size, quantity);
    const updatedCartProducts: CartProduct[] = [
      ...this.state.cartProducts.slice(0, index),
      updatedCartProduct,
      ...this.state.cartProducts.slice(index + 1),
    ];
    this.setState({
      cartProducts: updatedCartProducts,
    });
    return this.setDebouncedQuoteItems(this.props.designId, updatedCartProducts);
  };

  public onDeleteButtonClick = (cartProductId: string) => () => {
    const { colorId } = JSON.parse(cartProductId);
    const colorNameLowerCase: string = colorId.replace(/-/, ' ');
    const productColorOfRemovedItem: ProductColor | undefined = this.state.productColors.find(
      (productColor: ProductColor) => {
        return productColor.name.toLowerCase() === colorNameLowerCase.toLowerCase();
      }
    );
    if (!productColorOfRemovedItem) {
      console.error("Couldn't found color for the item wanted to be removed");
      return;
    }
    const { onDeleteButtonClicked } = this.props;

    return onDeleteButtonClicked(productColorOfRemovedItem.name);
  };

  public renderQuantitySelector({ design, hasSizeGuide }: any) {
    const {
      cartProducts,
      productColors,
      showSizeGuideSizes,
      debouncedQuoteItems,
      quoteSubtotal,
      unitPrice,
      computedProductQuantity,
    } = this.state;

    const { currentProduct, designId, cartId } = this.props;
    const currentProductName: string = currentProduct.name;

    const designSides: DesignSide[] = design.sides;
    const designSideToDisplay: DesignSide | undefined = designSides.find((designSide) => {
      return designSide.shapes.length > 0;
    });
    const designSideToDisplayKey: string = designSideToDisplay
      ? DESIGN_SIDE_ENUM[designSideToDisplay.name]
      : DESIGN_SIDE_ENUM.FRONT;

    const debouncedItemsExist = debouncedQuoteItems.length > 0;
    let singleUnitQuoteItems: CompleteQuoteItemsWithoutPrintType[] = [];
    if (debouncedItemsExist) {
      const singleUnitQuoteItem = _.cloneDeep(debouncedQuoteItems[0]);
      singleUnitQuoteItem.product.quantity = 1;
      singleUnitQuoteItems = [singleUnitQuoteItem];
    }

    return (
      <QueryRequestCompleteQuote
        skip={!debouncedItemsExist}
        query={QUERY_REQUEST_COMPLETE_QUOTE_WITHOUT_PRINT_TYPE}
        fetchPolicy="no-cache"
        variables={{ items: singleUnitQuoteItems }}
        onCompleted={(data: any) => {
          const unitQuotePrices = _.get(data, 'requestCompleteQuoteWithoutPrintType');
          const unitPrice = unitQuotePrices
            ? _.sumBy(unitQuotePrices, (itemQuote: RequestCompleteQuoteOutput) => {
                return itemQuote.amounts.total;
              })
            : 0;
          this.setState({ unitPrice });
        }}
      >
        {({ error: unitQuoteError, loading: unitQuoteLoading, data: unitQuoteData }) => {
          return (
            <div className="productQuantities pt-1 md:pt-1p5 pb-9 md:pb-8 md:p-static">
              {cartProducts.map((cartProduct: CartProduct, idx: number) => {
                const currentColorId: string = cartProduct.colorId;
                const currentColorKey: string = currentColorId.replace(/\-/gi, ' ');
                const currentColor: ProductColor | undefined = productColors.find((productColor: ProductColor) => {
                  return productColor.name.toLowerCase() === currentColorKey.toLowerCase();
                });
                if (!currentColor) {
                  throw new Error(`No color was found for the given colorId: ${currentColorId}`);
                }
                const currentColorName: string = currentColor.name;
                const designTemplate: DesignTemplate | undefined = currentProduct.designTemplates.find(
                  (designTemplate) => {
                    return designTemplate.side === designSideToDisplayKey;
                  }
                );
                const templateIdentifier: string = designTemplate ? designTemplate.id : '';
                const color: string = currentColor.hex;
                const side: string = designSideToDisplayKey;
                const size: string = MOCKUP_SIZE;
                let mockupUrl: string = '';
                if (templateIdentifier) {
                  mockupUrl = url('mockup', mockupRoutes.mockup, {
                    designId,
                    templateIdentifier,
                    color,
                    side,
                    size,
                  });
                }

                const availableSizes: ProductSize[] = currentColor.sizesObjects || [];
                const sizesQuantities: { [id: string]: number } = cartProduct.sizesQuantities;
                const inputQuantitiesSum = _.sum(_.values(cartProduct.sizesQuantities));
                const sizeDisplays = _.compact(
                  availableSizes.map((availableSize: ProductSize) => {
                    if (!sizesQuantities[availableSize.name]) return null;
                    return `${prettyProductSize(availableSize.name)}-${sizesQuantities[availableSize.name]}`;
                  })
                );
                const inksDisplay = designInksDisplay(design);
                const bulkPricePerPiece =
                  computedProductQuantity > 0 ? Math.floor(quoteSubtotal / computedProductQuantity) : 0;
                const unitPricePerPiece = unitPrice;
                const unitSubtotal = unitPricePerPiece * computedProductQuantity;
                const discountPercent =
                  unitSubtotal > 0 ? Math.round(((unitSubtotal - quoteSubtotal) / unitSubtotal) * 100) : 0;

                const twoWeekDeliverBy = GetDeliverByDate(new Date().toString(), SHIPPING_SPEED_ENUM.NINE_DAY);
                const twoWeekDeliverByDisplay = moment(twoWeekDeliverBy).format('ddd, MMM D');

                return (
                  <div key={`cartProduct_${idx}`}>
                    <div className="bwb-1 bc-gray-200 px-1 lg:px-3" key={cartProduct.id}>
                      <div className="flex pb-p5 md:pb-1">
                        <div className="w-1/2">
                          <span className="fs-lg fw-black">Select Sizes</span>
                        </div>
                        <div className="w-1/2 flex justify-end items-end">
                          <span className="fs-md fw-bold">Total Qty: {computedProductQuantity}</span>
                        </div>
                      </div>
                      <div className="flex">
                        <div className="w-1/2">
                          <span className="fs-md fw-bold color-gray-800">Unisex Sizes</span>
                        </div>
                        <div className="w-1/2 flex justify-end">
                          <button className="" onClick={this.showSizeGuide(_.map(availableSizes, 'name'))}>
                            <img src={STRAIGHTEN_SVG} className="color-navy-800 fs-icon-p5" />
                            <span className="fs-xs fw-bold pl-p25 color-gray-800">Size Guide</span>
                          </button>
                        </div>
                      </div>

                      {/* {We will add the note later} */}
                      {false && (
                        <div className="special-note-container my-1">
                          <TextArea label="Add Special Note" />
                          <div className="flex justify-end mt-1">
                            <button className="color-navy fw-normal capitalize mr-1p5">cancel</button>
                            <Button className="capitalize" type="default" size="md" color="primary">
                              add note
                            </Button>
                          </div>
                        </div>
                      )}

                      <ProductQuantitySelector
                        className="md:mt-p5 mb-1"
                        sizeBoxPaddingX="p5"
                        availableSizes={availableSizes}
                        sizesQuantities={sizesQuantities}
                        errors={getCartProductErrors(cartProduct)}
                        warnings={getCartProductWarnings(cartProduct)}
                        onQuantityChange={this.onQuantityChange(cartProduct.id)}
                      />
                    </div>
                    <div className="mt-1 md:mt-1p5 px-1 lg:px-3">
                      <div className="w-full flex justify-between">
                        <span className="fs-lg fw-black">All-Inclusive Price</span>
                        {/* {We will add the share quote later} */}
                        <span></span>
                        {false && (
                          <span className="color-primary fw-bold cursor-pointer">
                            Share Quote<i className="color-primary ml-3px fs-icon-1p5 mdi mdi-send"></i>
                          </span>
                        )}
                      </div>
                      <div className="br mt-1 bw-1 bc-gray-300">
                        <div className="product-list-details w-full flex-row br p-relative bgc-white">
                          <div className="flex">
                            <div className="p-p5 lg:p-p75 lg:flex-no-shrink lg:flex-no-grow lg:flex-basis-auto lg:w-auto">
                              <div className="image-container br" style={{ height: '112px', width: '92px' }}>
                                <Img
                                  src={[mockupUrl]}
                                  loader={<Loading textClassName="d-n" />}
                                  container={(children: any) => {
                                    return <div className="br overflow-hidden">{children}</div>;
                                  }}
                                />
                              </div>
                            </div>

                            <div className="w-full py-p5 pr-p5 pb-25 flex flex-col justify-between lg:p-p75 lg:pb-p5 lg:flex-no-shrink lg:flex-grow lg:flex-basis-auto lg:w-auto">
                              <div className="flex-row">
                                <p className="fs-xs color-navy capitalize fw-bold">{currentProductName}</p>
                                <div className="mt-p25 lg:mt-p25">
                                  <p className="lg:d-ib">
                                    <span className="fs-xs color-navy-700 capitalize">{currentColorName}</span>
                                  </p>
                                </div>
                                {inksDisplay && (
                                  <div className="mt-p25 lg:mt-p25">
                                    <p className="lg:d-ib">
                                      <span className="fs-xs color-navy-700 capitalize">{inksDisplay}</span>
                                    </p>
                                  </div>
                                )}
                                {sizeDisplays.length > 0 && (
                                  <div className="mt-p25 lg:mt-p25">
                                    <p className="lg:d-ib">
                                      <span className="fs-xs color-navy-700 capitalize">
                                        Sizes{`(${inputQuantitiesSum})`}: {sizeDisplays.join(', ')}
                                      </span>
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          {bulkPricePerPiece > 0 && quoteSubtotal > 0 ? (
                            <div className="w-full py-1 px-1p5" style={{ backgroundColor: '#F8F9FD' }}>
                              <div className="flex">
                                <div className="w-1/2 items-end">
                                  <span className="fw-extra-bold color-primary fs-xl">{`$${formatPrice(
                                    bulkPricePerPiece
                                  )}`}</span>
                                  <span className="fw-bold color-gray-700"> each</span>
                                </div>
                                <div className="w-1/2 flex items-end justify-end">
                                  <span>
                                    <span className="fw-bold color-gray-700 mr-3px">Total</span>
                                    <span className="fw-bold color-primary">{`$${formatPrice(quoteSubtotal)}`}</span>
                                  </span>
                                </div>
                              </div>
                              {unitPricePerPiece > bulkPricePerPiece && (
                                <div className="flex">
                                  <div className="w-1/2 items-end">
                                    <span className="line-through">{`$${formatPrice(unitPricePerPiece)}`}</span>
                                  </div>
                                  <div className="w-1/2 flex items-end justify-end">
                                    <span className="line-through">{`$${formatPrice(unitSubtotal)}`}</span>
                                  </div>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div
                              className="w-full flex justify-center py-1p5 px-1p5"
                              style={{ backgroundColor: '#F8F9FD' }}
                            >
                              <span className="fs-md fw-bold color-gray-800 ta-center">
                                Enter sizes above to get your All-Inclusive Price
                              </span>
                            </div>
                          )}

                          <ul className="py-p5 pl-2p5 color-green fw-bold">
                            <li className="fw-bold color-green">
                              <span className="fs-xs p-relative" style={{ left: '-5px' }}>
                                {discountPercent > 0
                                  ? `${discountPercent}% Volume Discount`
                                  : `Order more products to get a volume discount!`}
                              </span>
                            </li>
                            <li>
                              <span
                                className="fw-normal color-black fs-xs p-relative"
                                style={{ color: '#2d3436', left: '-5px' }}
                              >
                                Guaranteed delivery by&nbsp;
                              </span>
                              <span className="fw-bold color-green fs-xs p-relative" style={{ left: '-5px' }}>
                                {twoWeekDeliverByDisplay}&nbsp;
                              </span>
                              <span
                                className="fw-normal color-black fs-xs p-relative"
                                style={{ color: '#2d3436', left: '-5px' }}
                              >
                                with Free 9 Day Delivery
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        }}
      </QueryRequestCompleteQuote>
    );
  }

  public renderAddToCart({ quoteLoading }: any) {
    const { onAddToCart, designId, cartId } = this.props;
    const { cartProducts, quoteSubtotal } = this.state;
    const totalItemsPerCartProduct: number[] = cartProducts.map(getCartProductTotalQuantity);
    return (
      <UpdateCartMutation
        mutation={UPDATE_CART_MUTATION}
        refetchQueries={(mutationResult) => {
          return [
            {
              query: QUERY_REQUEST_CART_QUOTE,
              variables: { cartId },
            },
          ];
        }}
        awaitRefetchQueries={true}
        onCompleted={({ updateCart }) => {
          const cartId: string = updateCart.id;
          const currentCartProducts: CartProduct[] = this.state.cartProducts;
          GTM.push(GTMTypes.CART);
          currentCartProducts.forEach((cartProduct: CartProduct) => {
            const { productId, designId, colorId: colorName } = cartProduct;
            GTM.push(GTMTypes.ITEM, { productId, designId, colorName, cartId });
            GTM.fire(GTMEvents.ADD_TO_CART);
          });

          onAddToCart && onAddToCart();

          this.props.history.push(routes.app.checkout.cart);
        }}
        update={(cache: DataProxy, { data: { updateCart } }: any) => {
          updateCurrentCart(cache, updateCart);
        }}
      >
        {(updateCart, { data, loading: updateCartLoading, error }) => (
          <div className="productAddToCart w-full md:w-2/5 p-fixed pin-b pin-r brt bwt-1 bc-gray-300 bgc-white">
            <div className="summary flex-row md:flex items-center py-1 px-1p5 fs-md color-navy justify-between">
              <div className="price d-b md:w-1/3">
                <div className="flex justify-between pb-p5 md:flex-col md:pb-0">
                  <div className="fw-extra-bold">All-Inclusive Price</div>
                  {quoteSubtotal > 0 ? (
                    <div className="fw-extra-bold color-primary fs-lg">{`$${formatPrice(quoteSubtotal)}`}</div>
                  ) : (
                    <div className="fw-extra-bold color-gray-500 fs-lg">$--</div>
                  )}
                </div>
              </div>
              <div className="addToCartButton md:w-2/3 lg:pl-1">
                <Button
                  type="default"
                  color="primary"
                  className="py-1 px-1 fs-md p-relative"
                  block={true}
                  onClick={this.onAddToCartButtonClicked(updateCart)}
                  disabled={
                    !_.some(totalItemsPerCartProduct, (itemTotal) => itemTotal > 0) ||
                    this.hasErrors(cartProducts) ||
                    updateCartLoading ||
                    quoteLoading
                  }
                >
                  {updateCartLoading || quoteLoading ? (
                    <Loading size="medium" textClassName="d-n" paddingClassName="p-0" />
                  ) : (
                    <div className="w-full flex justify-between py-p25">
                      <span />
                      <div style={{ fontSize: '18px' }}>Add to Cart</div>
                      <i className="fs-icon-1p5 mdi mdi-arrow-right"></i>
                    </div>
                  )}
                </Button>
              </div>
            </div>
          </div>
        )}
      </UpdateCartMutation>
    );
  }

  render() {
    const {
      cartProducts,
      productColors,
      showMoreColors,
      showSizeGuide,
      showSizeGuideSizes,
      debouncedQuoteItems,
    } = this.state;
    const { currentProduct, onColorSelected, onAddToCart, designId, cartId } = this.props;
    const currentProductBrand: string = (
      _.find(currentProduct.categories, (category) => {
        return category.scope === PRODUCT_CATEGORY_SCOPES.BRAND;
      }) || { name: 'UNKNOWN' }
    ).name;
    const hasSizeGuide = SIZES_BY_BRAND[currentProductBrand];
    const quoteItemsWithQty: CompleteQuoteItemsWithoutPrintType[] = debouncedQuoteItems.filter(
      ({ product }) => product.quantity > 0
    );

    return (
      <QueryDesignById query={QUERY_DESIGN_BY_ID} variables={{ id: designId }}>
        {({ error: designByIdError, loading: designByIdLoading, data: designByIdData }) => (
          <QueryRequestCompleteQuote
            skip={quoteItemsWithQty.length === 0}
            query={QUERY_REQUEST_COMPLETE_QUOTE_WITHOUT_PRINT_TYPE}
            fetchPolicy="no-cache"
            variables={{ items: quoteItemsWithQty }}
            onCompleted={(data: any) => {
              const quotePrices = _.get(data, 'requestCompleteQuoteWithoutPrintType');
              const quoteSubtotal = quotePrices
                ? _.sumBy(quotePrices, (itemQuote: RequestCompleteQuoteOutput) => {
                    return itemQuote.amounts.total;
                  })
                : 0;
              const computedProductQuantity = this.state.cartProducts.reduce(
                (total: number, cartProduct: CartProduct) => {
                  const inputQuantitiesSum = _.sum(_.values(cartProduct.sizesQuantities));
                  return total + inputQuantitiesSum;
                },
                0
              );
              this.setState({ quoteSubtotal, computedProductQuantity });
            }}
          >
            {({ error: quoteError, loading: quoteLoading, data: quoteData }) => {
              if (designByIdError || quoteError) {
                return (
                  <Alert
                    alerts={[
                      {
                        type: 'error',
                        title: 'Design Tool Error',
                        content: 'Oops! Something went wrong. We are very sorry. Please try again later or contact us.',
                      },
                    ]}
                  />
                );
              }

              if (designByIdLoading || !designByIdData) {
                return (
                  <div className="loading-overlay w-full h-full flex justify-center items-center p-absolute pin bgc-white-70">
                    <Loading size="large" />
                  </div>
                );
              }

              const { designById: design } = designByIdData;
              return (
                <React.Fragment>
                  {this.renderQuantitySelector({ design, hasSizeGuide })}
                  {this.renderAddToCart({ quoteLoading })}

                  {hasSizeGuide && showSizeGuide && (
                    <Modal
                      onCloseClick={this.hideSizeGuide()}
                      onOverlayClick={this.hideSizeGuide()}
                      title="Size guide"
                      headerPadding="px-1p25 py-p75"
                      headerTextAlignment=" center"
                    >
                      <SizeGuide
                        className="fs-xs md:fs-sm m-1"
                        product={currentProduct}
                        brand={currentProductBrand}
                        sizes={showSizeGuideSizes}
                      />
                    </Modal>
                  )}
                </React.Fragment>
              );
            }}
          </QueryRequestCompleteQuote>
        )}
      </QueryDesignById>
    );
  }
}

export default withRouter(ProductsQuantitySelector);
