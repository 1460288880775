import * as React from 'react';
import * as Qs from 'qs';
import _ from 'lodash';
import { Link, RouteComponentProps, Redirect } from 'react-router-dom';
import gql from 'graphql-tag';
import { Query, Mutation, MutationFn, withApollo, WithApolloClient } from 'react-apollo';
import { ApolloError } from 'apollo-client';
import Helmet from 'react-helmet';
import { DataProxy } from 'apollo-cache';

import { url, routes, path } from 'inkp-routes/public';
import Tab from 'inkp-components/dist/Components/Tab';
import Input from 'inkp-components/dist/Components/Input';
import PrimaryButton from 'inkp-components/dist/Components/PrimaryButton';
import Notif from 'inkp-components/dist/Components/Notif';
import OrderDisplayer from 'inkp-components/dist/Components/OrderDisplayer';

import { validatePassword, isUserRegistered, formatToPhone } from 'inkp-user-sdk/user';
import { ORDER_EVENT_ENUM, ORDER_HOLD_REASONS, OrderEvent } from 'inkp-order-sdk/types.g';
import {
  ORDER_STATUS_ENUM_MAPPING,
  ORDER_HOLD_ARTWORK_REASONS,
  ORDER_HOLD_REASON_MAP,
  formatDesignChangeLink,
} from 'inkp-order-sdk/order';

import mockupRoutes from 'inkp-mockup-sdk/routes';
import { StateContainer, Subscribe, getContainers } from 'infra-frontend/helpers/apollo';
import Loading from 'inkp-components/dist/Components/Loading';

// Local Components
import SavedDesigns from '../../components/SavedDesigns';
import YouDontHave from '../../components/YouDontHave';

// Helper
import { LIST_ORDER_EVENTS_QUERY } from '../../util/orderEvents';

// GTM helpers
import GTM from '../../util/gtm';
import { GTMTypes } from '../../interfaces/GTM';

// Interfaces
import { FieldError } from '../../interfaces';

import {
  CURRENT_USER_FRAGMENT,
  updateCurrentUser,
  CurrentUserData,
  CURRENT_USER_QUERY,
  signOutUser,
  getCurrentUser,
} from '../../util/login';

import { GET_MY_ORDERS_QUERY } from '../../util/orders';
import { USER_ROLE } from 'inkp-user-sdk/types.g';

const UPDATE_DETAILS_MUTATION = gql`
  mutation UpdateUser($userId: String!, $email: String!, $name: String!, $phone: String, $organization: String) {
    updateUser(userData: { userId: $userId, email: $email, name: $name, phone: $phone, organization: $organization }) {
      ...CurrentUser
    }
  }
  ${CURRENT_USER_FRAGMENT}
`;

const UPDATE_PASSWORD_MUTATION = gql`
  mutation UpdateUserPassword($userId: String!, $currentPassword: String!, $newPassword: String!) {
    updateUserPassword(userData: { userId: $userId, currentPassword: $currentPassword, newPassword: $newPassword }) {
      ...CurrentUser
    }
  }

  ${CURRENT_USER_FRAGMENT}
`;

const SIGN_OUT_MUTATION = gql`
  mutation SignOut {
    signOut {
      ...CurrentUser
    }
  }

  ${CURRENT_USER_FRAGMENT}
`;

const REGISTRATION_SUCCESS_THANK_YOU_BLURB = 'Thank you for registering. You may track your order here.';

interface Fields {
  userId: string;
  name: string;
  email: string;
  organization: string;
  phone: string;
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

interface ContainerState {
  fields: Fields;
  initialValues: Fields;
  showPasswordChangePassword: boolean;
  showNotif: boolean;
  notifMessage: string;
  notifType: 'success' | 'warning' | 'error';
  errors: FieldError[];
}

class MyAccountState extends StateContainer {
  initialState: ContainerState = {
    fields: {
      userId: '',
      name: '',
      email: '',
      organization: '',
      phone: '',
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    initialValues: {
      userId: '',
      name: '',
      email: '',
      organization: '',
      phone: '',
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    showPasswordChangePassword: false,
    showNotif: false,
    notifMessage: '',
    notifType: 'success',
    errors: [],
  };

  shape = `
    {
      fields {
        userId
        name
        email
        organization
        phone
        currentPassword
        newPassword
        confirmNewPassword
      }
      initialValues {
        userId
        name
        email
        organization
        phone
        currentPassword
        newPassword
        confirmNewPassword
      }
      showPasswordChangePassword
      showNotif
      notifMessage
      notifType
      errors {
        valid
        message
        field
      }
    }
  `;

  updateField = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue: string = event.target.value;
    if (field === 'phone') {
      newValue = formatToPhone(event.target.value);
    }

    if (field === 'email') {
      newValue = newValue.toLowerCase();
    }

    const fields = Object.assign({}, this.state.fields, { [field]: newValue });

    this.setState({ fields });
  };

  setInitialValue = ({
    name,
    email,
    userId,
    organization,
    phone,
  }: {
    name: string;
    email: string;
    userId: string;
    organization: string;
    phone: string;
  }) => {
    this.setState({
      fields: { name, email, userId, organization, phone },
      initialValues: { name, email, userId, organization, phone },
    });
  };

  toggleChangePassword = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    this.setState({ showPasswordChangePassword: !this.state.showPasswordChangePassword });
  };

  displayNotif = (notifMessage: string, notifType: string) => () => {
    this.setState({ showNotif: true, notifMessage, notifType });
    window.setTimeout(() => {
      this.setState({ showNotif: false, notifMessage: '', notifType: '' });
    }, 3000);
  };

  setErrors = (errors: any) => {
    this.setState({ errors });
  };

  validateDetailsSubmit = (updateUser: MutationFn) => {
    const {
      fields: { phone },
    } = this.state;
    const errors: FieldError[] = [];

    if (phone) {
      const phoneWithFormat: string = phone.replace(/\D/g, '').substring(0, 10);
      if (phoneWithFormat.length > 1 && phoneWithFormat.length < 10) {
        errors.push({
          valid: false,
          message: 'The phone must have 10 digits',
          field: 'phone',
        });
      }
    }

    if (errors.length > 0) {
      this.setErrors(errors);
    } else {
      this.setErrors(errors);
      updateUser();
    }
  };

  validatePasswordSubmit = (updateUserPassword: MutationFn) => {
    const {
      fields: { newPassword, confirmNewPassword },
    } = this.state;
    const errors: FieldError[] = [];
    const passwordValidation = validatePassword(newPassword);

    if (newPassword !== confirmNewPassword) {
      errors.push({
        valid: false,
        message: 'Please type the same password',
        field: 'confirmNewPassword',
      });
    }

    if (!passwordValidation.valid) {
      errors.push({
        valid: passwordValidation.valid,
        message: passwordValidation.message,
        field: 'newPassword',
      });
    }

    if (errors.length > 0) {
      this.setErrors(errors);
    } else {
      this.setErrors(errors);
      updateUserPassword();
    }
  };

  setErrorsPassword = (graphQLErrors: any) => {
    const errors: FieldError[] = [];
    for (const error of graphQLErrors) {
      const {
        extensions: { downstreamErrors },
      } = error;
      for (const err of downstreamErrors) {
        const {
          data: { message },
        } = err;
        errors.push({
          valid: false,
          message,
          field: 'newPassword',
        });
      }
    }

    this.setErrors(errors);
  };
}

const parseOptions: Qs.IParseOptions = {
  ignoreQueryPrefix: true,
};

interface State {
  tabs: {
    displayName: string;
    active: boolean;
  }[];
  registrationSuccessful: boolean | undefined;
}

class MyAccount extends React.Component<WithApolloClient<RouteComponentProps>> {
  readonly state: State;

  constructor(props: WithApolloClient<RouteComponentProps>) {
    super(props);
    const {
      history: {
        location: { state },
      },
    } = props;
    const initialState: State = {
      tabs: [
        {
          displayName: 'Orders',
          active: false,
        },
        {
          displayName: 'Saved Designs',
          active: false,
        },
        {
          displayName: 'Account Details',
          active: false,
        },
      ],
      registrationSuccessful: undefined,
    };
    this.state = Object.assign({}, initialState, state);
  }

  signOutUpdate = () => (cache: DataProxy, { data }: { data: { signOut: null } }) => {
    signOutUser(
      cache,
      {
        id: null,
        email: null,
        name: null,
        role: null,
        organization: null,
      },
      CURRENT_USER_QUERY
    );
  };

  renderLoadingOrError = (loading: boolean, error: boolean) => {
    if (loading) {
      return <Loading size="large" />;
    }
    if (error) {
      return <div>Error</div>;
    }
  };

  completeSignOut = () => () => {
    window.location.href = url('app', routes.app.base);
  };

  componentDidMount() {
    const {
      location: { search },
    } = this.props;
    const { tab } = Qs.parse(search, parseOptions);
    this.updateTab(tab);

    setTimeout(() => {
      GTM.push(GTMTypes.USER);
    }, 0);
  }

  updateTab = (field: string) => {
    const { tabs } = this.state;
    const newTabs = tabs.map((tab: any) => {
      let newTab = {};
      if (tab.displayName === field) {
        newTab = Object.assign({}, { ...tab }, { active: true });
      } else {
        newTab = Object.assign({}, { ...tab }, { active: false });
      }
      return newTab;
    });
    this.setState({ tabs: newTabs });
    this.props.history.push({
      search: Qs.stringify({ tab: field }),
    });
  };

  accountDetailsUpdate = () => (cache: DataProxy, { data }: { data: { updateUser: CurrentUserData } }) => {
    updateCurrentUser(cache, data.updateUser);
  };

  redirectToCartPage = (_cartId: string) => {
    this.props.history.push(routes.app.checkout.cart);
  };

  redirectToDesignPage = (product: any, colorName: string) => {
    this.props.history.push(path(routes.app.designTool, { product: product.id, color: colorName }));
  };

  displayPasswordError = (error: ApolloError, setErrorsPassword: (graphQLErrors: any) => void) => {
    const { graphQLErrors } = error;
    setErrorsPassword(graphQLErrors);
  };

  renderContent = ({
    state,
    updateField,
    toggleChangePassword,
    displayNotif,
    client,
    validatePasswordSubmit,
    validateDetailsSubmit,
    setErrorsPassword,
  }: {
    state: ContainerState;
    updateField: (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
    toggleChangePassword: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    displayNotif: (notifMessage: string, notifType: string) => () => void;
    client: any;
    validatePasswordSubmit: (updateUserPassword: MutationFn) => void;
    validateDetailsSubmit: (updateUser: MutationFn) => void;
    setErrorsPassword: (graphQLErrors: any) => void;
  }) => {
    const {
      location: { search },
      history,
    } = this.props;
    let content = null;
    const {
      fields: { userId, name, email, organization, phone, currentPassword, newPassword, confirmNewPassword },
      initialValues: {
        userId: userIdInitial,
        name: nameInitial,
        email: emailInitial,
        organization: organizationInitial,
        phone: phoneInitial,
        currentPassword: currentPasswordInitial,
        newPassword: newPasswordInitial,
        confirmNewPassword: confirmNewPasswordInitial,
      },
      showPasswordChangePassword,
    } = state;
    const user = getCurrentUser(this.props.client.cache);
    const { tab } = Qs.parse(search, parseOptions);
    if (!user || user.role === USER_ROLE.ANON) {
      return (
        <Redirect to={path(routes.app.account.signIn, { redirect: path(routes.app.account.myAccount, { tab }) })} />
      );
    }
    switch (tab) {
      case 'Account Details':
        content = (
          <React.Fragment>
            <Mutation
              mutation={UPDATE_DETAILS_MUTATION}
              onCompleted={displayNotif('Details changed Successfully!', 'success')}
              update={this.accountDetailsUpdate()}
              onError={displayNotif('Something went wrong', 'error')}
              variables={{ email, userId, name, organization, phone }}
            >
              {(updateUser: MutationFn) => {
                const phoneError = _.find(state.errors, { field: 'phone' });
                const fieldsObj: any = { name, email, organization, phone };
                const passwordsObj: any = { newPassword, confirmNewPassword };
                const initialObj: any = {
                  name: nameInitial,
                  email: emailInitial,
                  organization: organizationInitial,
                  phone: phoneInitial,
                };
                const initialPasswordObj: any = {
                  newPassword: newPasswordInitial,
                  confirmNewPassword: confirmNewPasswordInitial,
                };
                const enableDetails: boolean = JSON.stringify(fieldsObj) === JSON.stringify(initialObj);
                const enablePassword: boolean = JSON.stringify(passwordsObj) === JSON.stringify(initialPasswordObj);
                return (
                  <div className="w-full md:w-1/2 md:mx-auto md:mt-3 bgc-white px-1 p-relative">
                    <h3
                      className="mt-2 fs-xl fw-extra-bold"
                      style={{
                        lineHeight: '36px',
                        textShadow: '0 2px 4px 0 rgba(31,34,69,0.2)',
                      }}
                    >
                      Account Details
                    </h3>
                    <h3 className="mt-1 fs-md fw-extra-bold md:mt-2">Contact Info</h3>
                    <Input
                      label="Full Name"
                      className="w-full mt-1p5"
                      inputType="text"
                      value={name}
                      onChange={updateField('name')}
                    />
                    <Input
                      label="Email"
                      className="w-full mt-1p5"
                      inputType="email"
                      value={email}
                      onChange={updateField('email')}
                      disabled={true}
                    />
                    <Input
                      label="Organization"
                      className="w-full mt-1p5"
                      inputType="text"
                      value={organization}
                      onChange={updateField('organization')}
                    />
                    <Input
                      label="Phone Number"
                      className="w-full mt-1p5"
                      inputType="text"
                      value={phone}
                      onChange={updateField('phone')}
                      error={phoneError && !phoneError.valid}
                      helper={phoneError ? phoneError.message : ''}
                      helperColor={phoneError && !phoneError.valid ? 'red' : 'green'}
                    />
                    <div className="mt-2 d-b">
                      <div className="d-ib w-1/2 ta-left">
                        {!showPasswordChangePassword ? (
                          <button
                            className="bgc-gray-50 color-navy-500 br-1p5 fs-md fw-bold"
                            onClick={toggleChangePassword}
                            style={{
                              height: 48,
                              width: 182,
                            }}
                          >
                            Change Password
                          </button>
                        ) : (
                          <div></div>
                        )}
                      </div>
                      <div className="d-ib w-1/2 ta-right">
                        <span className="cursor-pointer mr-1p5">Cancel</span>
                        <PrimaryButton
                          color="primary"
                          onClick={() => validateDetailsSubmit(updateUser)}
                          disabled={enableDetails}
                        >
                          Done
                        </PrimaryButton>
                      </div>
                    </div>
                    {showPasswordChangePassword ? (
                      <Mutation
                        mutation={UPDATE_PASSWORD_MUTATION}
                        onCompleted={displayNotif('Password changed Successfully!', 'success')}
                        update={this.accountDetailsUpdate()}
                        onError={(error: ApolloError) => this.displayPasswordError(error, setErrorsPassword)}
                        variables={{ userId, currentPassword, newPassword }}
                      >
                        {(updateUserPassword: MutationFn) => {
                          const newPasswordError = _.find(state.errors, { field: 'newPassword' });
                          const confirmNewPasswordError = _.find(state.errors, { field: 'confirmNewPassword' });
                          return (
                            <div className="mt-1p5 mb-4 ta-left">
                              <h3 className="color-navy fs-md">Change Password</h3>
                              <Input
                                label="Current Password"
                                className="w-full mt-1p5"
                                inputType="password"
                                value={currentPassword}
                                onChange={updateField('currentPassword')}
                              />
                              <Input
                                label="New Password"
                                className="w-full mt-1p5"
                                inputType="password"
                                value={newPassword}
                                onChange={updateField('newPassword')}
                                error={newPasswordError && !newPasswordError.valid}
                                helper={newPasswordError ? newPasswordError.message : ''}
                                helperColor={newPasswordError && !newPasswordError.valid ? 'red' : 'green'}
                              />
                              <Input
                                label="Confirm New Password"
                                className="w-full mt-1p5"
                                inputType="password"
                                value={confirmNewPassword}
                                onChange={updateField('confirmNewPassword')}
                                error={confirmNewPasswordError && !confirmNewPasswordError.valid}
                                helper={confirmNewPasswordError ? confirmNewPasswordError.message : ''}
                                helperColor={
                                  confirmNewPasswordError && !confirmNewPasswordError.valid ? 'red' : 'green'
                                }
                              />
                              <div className="mt-1p5 mb-4 ta-right">
                                <span className="cursor-pointer mr-1p5" onClick={toggleChangePassword}>
                                  Cancel
                                </span>
                                <PrimaryButton
                                  color="primary"
                                  onClick={() => validatePasswordSubmit(updateUserPassword)}
                                  disabled={enablePassword}
                                >
                                  Change Password
                                </PrimaryButton>
                              </div>
                            </div>
                          );
                        }}
                      </Mutation>
                    ) : null}
                  </div>
                );
              }}
            </Mutation>
          </React.Fragment>
        );
        break;
      case 'Saved Designs':
        content = (
          <SavedDesigns
            currentUser={user}
            userId={user.id}
            onAddToCartClick={this.redirectToCartPage}
            userEmail={''}
            onStartDesignClick={this.redirectToDesignPage}
          />
        );
        break;
      case 'Orders':
        content = (
          <React.Fragment>
            <Query
              query={GET_MY_ORDERS_QUERY}
              variables={{
                input: {
                  query: {},
                  pagination: {
                    skip: 0,
                    limit: 10,
                    sortBy: {
                      orderedAt: -1,
                    },
                  },
                },
              }}
              fetchPolicy="network-only"
            >
              {({ loading: orderLoading, error: orderError, data: orderData }: any) => (
                <Query
                  skip={!orderData || !orderData.ordersByUser || !orderData.ordersByUser.orders}
                  query={LIST_ORDER_EVENTS_QUERY}
                  variables={{
                    query: {
                      orderIds: orderData && orderData.ordersByUser && _.map(orderData.ordersByUser.orders, 'id'),
                      names: [ORDER_EVENT_ENUM.ON_HOLD],
                    },
                  }}
                >
                  {({ loading: eventsLoading, error: eventsError, data: eventsData }: any) => {
                    const loading = orderLoading || eventsLoading;
                    const error = orderError || eventsError;
                    const ordersOpen: any[] = [];
                    const ordersClosed: any[] = [];
                    if (orderData && orderData.ordersByUser) {
                      const {
                        ordersByUser: { orders: ordersInfo },
                      } = orderData;
                      ordersInfo.forEach((order: any) => {
                        const {
                          status,
                          quote: { items },
                          billingAddress,
                          shippingAddress,
                          features,
                        } = order;
                        const dictionary: any = {};
                        let holdEvent: OrderEvent | null = null;

                        const user = getCurrentUser(this.props.client.cache);

                        const productHeader: any = {
                          viewDetailsLinkPath: path(routes.app.order.getById, { orderId: order.id }),
                          status,
                          deliveringDate: new Date(order.dueAt),
                          orderCreationDate: new Date(order.orderedAt),
                          total: order.quote.amounts.total,
                          orderItemGroups: [],
                          showDetail: false,
                          requiresCustomerApproval: false,
                          onApproveUrl: `${routes.app.account.artworkApproval}?orderId=${order.id}`,
                        };

                        if (features) {
                          productHeader.requiresCustomerApproval = features.requiresCustomerApproval;
                        }

                        if (eventsData && eventsData.listOrderEvents) {
                          const orderHoldEvents = _.filter(eventsData.listOrderEvents, { orderId: order.id });
                          holdEvent = _.sortBy(orderHoldEvents, (orderEvent: OrderEvent) => {
                            return -1 * new Date(orderEvent.createdAt).getTime();
                          })[0];
                        }

                        items.forEach((item: any) => {
                          const {
                            designId,
                            product: { product, productItem },
                            amounts,
                            tracking,
                          } = item;
                          const key: string = (tracking && tracking.number) || 'na';
                          const trackingLink = tracking && tracking.link;
                          let holdReasons = null;
                          let resubmitArtworkLink = null;
                          let colorProductItem: any = item.product.productItem;
                          if (!item.product.productItem) {
                            colorProductItem = { color: item.product.product.colors[0].name, size: 'lrg' };
                          }
                          const productItemKey = `${item.product.product.id}-${designId}-${colorProductItem.color}`;
                          const templateIdentifier: any = item.product.product.designTemplates.find(
                            (designTemplate: any) => {
                              return designTemplate.side === item.design.sides[0].name;
                            }
                          );
                          let mockupUrl: string = product.image.url;
                          const designColorHex = product.colors.find((color: any) => {
                            return color.name === colorProductItem.color;
                          }).hex;
                          if (templateIdentifier) {
                            mockupUrl = url('mockup', mockupRoutes.mockup, {
                              designId: item.designId,
                              templateIdentifier: templateIdentifier.id,
                              color: designColorHex,
                              side: item.design.sides[0].name,
                              size: 'medium',
                            });
                          }
                          const rejectedDesign =
                            holdEvent && holdEvent.data && _.find(holdEvent.data.rejectedDesigns, { designId });
                          if (rejectedDesign && rejectedDesign.reasons) {
                            holdReasons = rejectedDesign.reasons.map((reason: ORDER_HOLD_REASONS) => {
                              return ORDER_HOLD_REASON_MAP[reason];
                            });
                            const artworkHoldReasons = _.filter(rejectedDesign.reasons, (reason) => {
                              return ORDER_HOLD_ARTWORK_REASONS.includes(reason);
                            });
                            if (artworkHoldReasons.length > 0) {
                              resubmitArtworkLink = formatDesignChangeLink(order, designId);
                            }
                          }
                          if (dictionary[key]) {
                            if (dictionary[key][productItemKey]) {
                              dictionary[key][productItemKey].quantity += Number(item.product.quantity);
                              dictionary[key][productItemKey].subtotal += Number(amounts.subtotal);
                              dictionary[key][productItemKey].productSizes.push({
                                name: colorProductItem.size.toUpperCase(),
                                quantity: item.product.quantity,
                              });
                            } else {
                              dictionary[key][productItemKey] = {
                                imageSrc: mockupUrl,
                                productName: product.name,
                                shareableName: item.design.shareable.name,
                                color: colorProductItem.color,
                                quantity: Number(item.product.quantity),
                                subtotal: Number(amounts.subtotal),
                                status: item.status,
                                trackingLink,
                                holdReasons,
                                resubmitArtworkLink,
                                showStatus: true,
                                productSizes: [
                                  { name: colorProductItem.size.toUpperCase(), quantity: item.product.quantity },
                                ],
                              };
                            }
                          } else {
                            dictionary[key] = {
                              [productItemKey]: {
                                imageSrc: mockupUrl,
                                productName: product.name,
                                shareableName: item.design.shareable.name,
                                color: colorProductItem.color,
                                quantity: Number(item.product.quantity),
                                subtotal: Number(amounts.subtotal),
                                status: item.status,
                                trackingLink,
                                holdReasons,
                                resubmitArtworkLink,
                                showStatus: true,
                                productSizes: [
                                  { name: colorProductItem.size.toUpperCase(), quantity: item.product.quantity },
                                ],
                              },
                            };
                          }
                        });

                        productHeader.orderItemGroups = Object.keys(dictionary).map((key: string) => {
                          return { key, items: Object.values(dictionary[key]) };
                        });
                        const delivered = `${ORDER_STATUS_ENUM_MAPPING.DELIVERED}`.toUpperCase();
                        const canceled = `${ORDER_STATUS_ENUM_MAPPING.CANCELLED}`.toUpperCase();
                        if (status === delivered || status === canceled) {
                          ordersClosed.push(productHeader);
                        } else {
                          ordersOpen.push(productHeader);
                        }
                      });
                    }
                    return (
                      <React.Fragment>
                        {this.renderLoadingOrError(loading, error)}
                        {!loading && !error ? (
                          <div className="mb-4">
                            {ordersOpen.length > 0 ? (
                              <div className="md:px-p75 md:mt-3">
                                <div className="w-full pt-2 pb-1 md:pt-0 md:pb-2 md:flex md:justify-between">
                                  <h2 className="w-1/2 d-ib ta-left md:fs-xl color-navy">Open Orders</h2>
                                  <span className="w-1/2 d-ib ta-right md:fs-md color-navy-500">
                                    {ordersOpen.length} Open Order{ordersOpen.length > 1 ? 's' : ''}
                                  </span>
                                </div>
                                {ordersOpen.map((order: any, index: number) => (
                                  <div className="mb-1p5 md:mb-2">
                                    <OrderDisplayer key={index} {...order} />
                                  </div>
                                ))}
                              </div>
                            ) : null}
                            {ordersClosed.length > 0 ? (
                              <div className="md:px-p75 md:mt-3">
                                <div className="w-full pt-2 pb-1 md:pt-0 md:pb-2 md:flex md:justify-between">
                                  <h2 className="w-1/2 d-ib ta-left md:fs-xl color-navy">Past Orders</h2>
                                  <span className="w-1/2 d-ib ta-right md:fs-md color-navy-500">
                                    {ordersClosed.length} Past Order{ordersClosed.length > 1 ? 's' : ''}
                                  </span>
                                </div>
                                {ordersClosed.map((order: any, index: number) => (
                                  <div className="mb-1p5 md:mb-2">
                                    <OrderDisplayer key={index} {...order} />
                                  </div>
                                ))}
                              </div>
                            ) : null}
                            {!ordersOpen.length && !ordersClosed.length ? (
                              <div className="">
                                <YouDontHave
                                  textDisplay="You have no orders yet!"
                                  onStartDesignClick={this.redirectToDesignPage}
                                />
                              </div>
                            ) : null}
                          </div>
                        ) : null}
                      </React.Fragment>
                    );
                  }}
                </Query>
              )}
            </Query>
          </React.Fragment>
        );
        break;
      default:
        break;
    }
    return content;
  };

  redirectToHome = () => {
    this.props.history.push({
      pathname: routes.app.base,
      search: '',
    });
  };

  render() {
    const { tabs, registrationSuccessful } = this.state;
    const {
      location: { search },
    } = this.props;
    const { tab } = Qs.parse(search, parseOptions);
    return (
      <React.Fragment>
        <Helmet>
          <title>My Account-{tab}</title>
        </Helmet>
        <Subscribe to={[MyAccountState]} namespace="MyAccount">
          {({
            state,
            updateField,
            setInitialValue,
            toggleChangePassword,
            displayNotif,
            validatePasswordSubmit,
            validateDetailsSubmit,
            setErrorsPassword,
          }) => {
            return (
              <Query query={CURRENT_USER_QUERY}>
                {({ loading: loadingUser, error: userLoadError, data: { currentUser }, client }: any) => {
                  const { email, id, name, organization, phone, role } = currentUser;
                  if (!loadingUser && !userLoadError && !isUserRegistered(currentUser)) {
                    this.redirectToHome();
                  }
                  if (state.fields.email.length === 0) {
                    setInitialValue({ name, email, userId: id, organization, phone });
                  }
                  return (
                    <React.Fragment>
                      <style jsx>
                        {`
                          .greet {
                            font-size: 36px;
                            line-height: 45px;
                          }

                          @media (min-width: 768px) {
                            .greet {
                              font-size: 56px;
                              line-height: 70px;
                            }
                          }
                        `}
                      </style>
                      {this.renderLoadingOrError(!id, userLoadError)}
                      {id ? (
                        <Mutation
                          mutation={SIGN_OUT_MUTATION}
                          onError={(error: any) => {
                            console.log('error on signout', error);
                          }}
                          onCompleted={this.completeSignOut()}
                          update={this.signOutUpdate()}
                        >
                          {(signOut: MutationFn) => {
                            if (registrationSuccessful) {
                              displayNotif(REGISTRATION_SUCCESS_THANK_YOU_BLURB, 'success')();
                              this.setState({
                                registrationSuccessful: undefined,
                              });
                            }
                            return (
                              <React.Fragment>
                                <div className="w-full bgc-gray-50">
                                  <div className="w-full flex flex-wrap px-p5 md:w-container md:mx-auto md:py-0">
                                    <div className="d-ib w-1/2 ta-left mt-p75">
                                      <Link
                                        to={routes.app.base}
                                        className={'color-blue flex items-center py-p75 md:py-p5'}
                                      >
                                        <i className="d-ib mdi mdi-chevron-left color-blue fs-xl" />
                                        <h3 className="d-ib fs-md align-text-bottom">Back to Home</h3>
                                      </Link>
                                    </div>
                                    <div className="d-ib w-1/2 ta-right cursor-pointer flex items-center justify-end mt-p75 md:mt-p5">
                                      <span
                                        className="d-n md:d-ib md:color-navy md:mr-1p5 color-navy-500 fs-md fw-normal"
                                        style={{
                                          fontWeight: 500,
                                        }}
                                      >
                                        {email}
                                      </span>
                                      <span
                                        className="color-navy-500 fs-md fw-bold py-p75 px-1p5"
                                        onClick={() => {
                                          signOut();
                                        }}
                                      >
                                        Log Out
                                      </span>
                                    </div>
                                    <div className="w-full ta-left mt-p75 md:mt-1p25">
                                      <h3 className="color-navy fw-extra-bold greet">Hi, {name}</h3>
                                    </div>
                                    <div className="w-full ta-left mt-p5 pb-p75 px-1 md:d-n">
                                      <span className="color-navy-500 fs-md" style={{ fontWeight: 500 }}>
                                        {email}
                                      </span>
                                    </div>
                                    <div className="w-full md:w-2/5 md:ta-left md:pr-0 md:mt-2">
                                      <Tab tabs={tabs} onClick={this.updateTab} />
                                    </div>
                                  </div>
                                </div>
                                <div className="w-full bgc-white mb-3">
                                  <div className="w-full px-1 md:w-container md:mx-auto md:p-0 p-relative">
                                    {state.showNotif ? (
                                      <Notif
                                        type={state.notifType}
                                        className="z-20 p-absolute w-9/12 mx-auto pin-x -mt-1"
                                      >
                                        <div>{state.notifMessage}</div>
                                      </Notif>
                                    ) : null}
                                    <div className="">
                                      {this.renderContent({
                                        state,
                                        updateField,
                                        toggleChangePassword,
                                        displayNotif,
                                        validatePasswordSubmit,
                                        validateDetailsSubmit,
                                        client,
                                        setErrorsPassword,
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </React.Fragment>
                            );
                          }}
                        </Mutation>
                      ) : null}
                    </React.Fragment>
                  );
                }}
              </Query>
            );
          }}
        </Subscribe>
      </React.Fragment>
    );
  }
}

export default withApollo(MyAccount);
