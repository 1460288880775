import gql from 'graphql-tag';

export const ORDER_FRAGMENT = gql`
  fragment OrderEventFragment on OrderEvent {
    id
    name
    scope
    description
    orderId
    data
    createdAt
  }
`

export const LIST_ORDER_EVENTS_QUERY = gql`
  query ListOrderEvents($query: OrderEventQuery!) {
    listOrderEvents(query: $query) {
      ...OrderEventFragment
    }
  }
  ${ORDER_FRAGMENT}
`;
