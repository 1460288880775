import * as React from 'react';
// @ts-ignore
import LoadingBar from 'react-top-loading-bar';
import mockupRoutes from 'inkp-mockup-sdk/routes';
import { url } from 'inkp-routes/public';
import { DesignCartState, DesignCartDesignSide } from '../../states/global/designCart';
import { findServiceError } from '../../util/apollo';
import {
  MUTATION_CREATE_SHARABLE,
  MUTATION_UPDATE_SHARABLE,
  MutationCreateSharable,
  MutationUpdateSharable,
} from '../../graphql/shareable';
import { MUTATION_UPDATE_USER, MutationUpdateUser, updateCurrentUser } from '../../graphql/users';
import { normalizeDesigns, normalizeProducts } from '../../util/shareable';
import { Product, ProductColor, DesignTemplate } from 'inkp-product-sdk/types.g';
import {
  DESIGN_SIDE_ENUM,
  DesignSide,
  DesignSideInput,
  SHAPE_TYPE_ENUM,
  ShapeInput,
  Shareable,
  ShareableDesignInput,
  ShareableProducItemInput,
  Mutation as MutationDesignResults,
} from 'inkp-design-sdk/types.g';
import { User, MutationupdateUserArgs } from 'inkp-user-sdk/types.g';
import { isUserRegistered } from 'inkp-user-sdk/user';

import Alert from 'inkp-components/dist/Components/Alert';
import Modal from 'inkp-components/dist/Components/Modal';
import { ApolloError } from 'apollo-client';

const MAX_PERCENTAGE = 99;
// Avg approximation for 1 side design -> save design mutation
const APROX_SAVE_TIME_PER_SIDE = 4;
// 1 second for the transition to 100%
const BUFFER_TIME = 1;
const MOCKUP_SIZE = 'medium';
const DEFAULT_MAX_ATTEMPTS = 1;

interface DuplicatedDesignData {
  id: string;
  name: string;
  email: string;
}

interface Props {
  designName: string;
  designState: DesignCartState;
  selectedColor: ProductColor;
  selectedProduct: Product;
  selectedTemplate: DesignTemplate;

  designIndex?: number;
  productIndex?: number;

  attempts?: number;

  onSaved: (shareable: Shareable, mockupUrl: string) => void;
  onCloseAlert?: () => void;
}

interface State {
  attemptsCounter: number;
  completed: boolean;
  counter: number;
  duplicatedDesignData: DuplicatedDesignData | undefined;
  isDuplicatedDesign: boolean;
  isUnknownError: boolean;
  loadingBarProgress: number;
  showErrorModal: boolean;
  showWarningModal: boolean;
  sidesToSave: number;
  stepPercentage: number;
  waitingTime: number;
}

function getNumberOfSidesWithShapes(sides: DesignCartDesignSide[]): number {
  return sides.filter((side) => {
    return side.shapes.length > 0;
  }).length;
}

function getEstimatedWaitingTime(sidesWithShapes: number): number {
  return sidesWithShapes * APROX_SAVE_TIME_PER_SIDE;
}

function getStepPercentage(seconds: number): number {
  return Math.ceil(MAX_PERCENTAGE / seconds);
}

function getProgressPercentageForStep(step: number, stepPercentage: number): number {
  let percentage: number = 0;
  if (step <= 0 || step === 0) {
    return percentage;
  }

  const previousStep = step - 1;
  const previousMaxPercentage = previousStep * stepPercentage;
  const currentMaxPercentage = step * stepPercentage;
  const minRange = previousMaxPercentage + 1;
  const maxRange = currentMaxPercentage;
  percentage = Math.floor(Math.random() * (maxRange - minRange + 1)) + minRange;

  return percentage;
}

export class SaveDesignProgress extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { designState, designIndex = 0 } = props;
    const { designs } = designState;
    const sidesToSave = getNumberOfSidesWithShapes(designs[designIndex].sides);
    const estimatedSeconds = getEstimatedWaitingTime(sidesToSave);
    const stepPercentage = getStepPercentage(estimatedSeconds);
    const counter = 0;
    const loadingBarProgress = 0;
    const completed = false;
    const attemptsCounter = 0;
    const showErrorModal = false;
    const showWarningModal = false;
    const isDuplicatedDesign = false;
    const duplicatedDesignData = undefined;
    const isUnknownError = false;

    this.state = {
      attemptsCounter,
      completed,
      counter,
      duplicatedDesignData,
      isDuplicatedDesign,
      isUnknownError,
      loadingBarProgress,
      showErrorModal,
      showWarningModal,
      sidesToSave,
      stepPercentage,
      waitingTime: estimatedSeconds + BUFFER_TIME,
    };
  }

  render() {
    const {
      selectedProduct,
      selectedColor,
      designState,
      designName,
      selectedTemplate,
      onSaved,
      attempts = DEFAULT_MAX_ATTEMPTS,
    } = this.props;
    const {
      completed,
      counter,
      duplicatedDesignData,
      isDuplicatedDesign,
      isUnknownError,
      loadingBarProgress,
      stepPercentage,
      waitingTime,
      attemptsCounter,
      showWarningModal,
      showErrorModal,
    } = this.state;

    const { products, designs } = designState;

    let timeoutId: number;

    const normalizedProducts: ShareableProducItemInput[] = normalizeProducts(products, selectedProduct, selectedColor);
    const normalizedDesigns: ShareableDesignInput[] = normalizeDesigns(designs);

    return (
      <MutationCreateSharable
        mutation={MUTATION_CREATE_SHARABLE}
        onCompleted={(result: MutationDesignResults) => {
          const { createShareable } = result;
          if (createShareable) {
            const product = createShareable.products[0];
            const newDesignId = product.designId;
            const preloadMockupUrl = url('mockup', mockupRoutes.mockup, {
              designId: newDesignId,
              templateIdentifier: selectedTemplate.id,
              color: selectedColor.hex,
              side: selectedTemplate.side,
              size: MOCKUP_SIZE,
            });
            const preloadedImg = new Image();
            preloadedImg.src = preloadMockupUrl;

            onSaved(createShareable, preloadMockupUrl);
            return this.setState({
              completed: true,
              loadingBarProgress: 100,
            });
          }
        }}
        onError={(mutationCreateShareableError: ApolloError) => {
          console.log('mutationCreateShareableError');
          console.log(mutationCreateShareableError);
          const serviceError = findServiceError(mutationCreateShareableError);
          if (serviceError && serviceError.extensions) {
            if (serviceError.extensions.code === 'DUPLICATE_DESIGN_NAME') {
              const { id, name, user } = serviceError.data.data;
              const duplicatedDesignData: DuplicatedDesignData = {
                id,
                name,
                email: user,
              };
              const isDuplicatedDesign = true;
              this.setState({
                duplicatedDesignData,
                isDuplicatedDesign,
                showWarningModal: false,
              });
            } else if (serviceError.extensions.code === 'INTERNAL_SERVER_ERROR') {
              // clear setTimeout
              if (timeoutId) {
                (global as any).clearTimeout(timeoutId);
              }
              this.setState({
                showWarningModal: attemptsCounter < attempts,
                showErrorModal: attemptsCounter === attempts,
                isUnknownError: true,
              });
            }
          } else {
            // it might be an error from other microservice.
            // clear setTimeout
            if (timeoutId) {
              (global as any).clearTimeout(timeoutId);
            }
            this.setState({
              showWarningModal: attemptsCounter < attempts,
              showErrorModal: attemptsCounter === attempts,
              isUnknownError: true,
            });
          }
        }}
      >
        {(
          createSharable,
          {
            data: mutationCreateShareableData,
            error: mutationCreateShareableError,
            loading: mutationCreateSharableLoading,
            called: mutationCreateSharableCalled,
          }
        ) => {
          const renderCreateDesignErrorModal = () => (
            <Modal>
              <Alert
                alerts={[
                  {
                    type: 'error',
                    title: "Oops! :'(",
                    content: 'Something went wrong. We are very sorry. Please try again later or contact us.',
                    onClose: () => {
                      return this.setState({
                        showErrorModal: false,
                      });
                    },
                  },
                ]}
              ></Alert>
            </Modal>
          );

          if (mutationCreateSharableLoading) {
            console.log('mutationCreateSharableLoading');
            console.log('mutationCreateSharableCalled', mutationCreateSharableCalled);
          }

          return (
            <MutationUpdateSharable
              mutation={MUTATION_UPDATE_SHARABLE}
              onCompleted={(result: MutationDesignResults) => {
                const { updateShareable } = result;

                if (updateShareable) {
                  const product = updateShareable.products[0];
                  const newDesignId = product.designId;
                  const preloadMockupUrl = url('mockup', mockupRoutes.mockup, {
                    designId: newDesignId,
                    templateIdentifier: selectedTemplate.id,
                    color: selectedColor.hex,
                    side: selectedTemplate.side,
                    size: MOCKUP_SIZE,
                  });
                  const preloadedImg = new Image();
                  preloadedImg.src = preloadMockupUrl;

                  onSaved(updateShareable, preloadMockupUrl);
                  return this.setState({
                    completed: true,
                    loadingBarProgress: 100,
                  });
                }
              }}
              onError={(mutationUpdateShareableError: ApolloError) => {
                console.log('mutationUpdateShareableError');
                console.log(mutationUpdateShareableError);
                this.setState({
                  showWarningModal: attemptsCounter < attempts,
                  showErrorModal: attemptsCounter === attempts,
                });
              }}
            >
              {(
                updateShareable,
                {
                  data: mutationUpdateShareableData,
                  error: mutationUpdateShareableError,
                  loading: mutationUpdateShareableLoading,
                  called: mutationUpdateShareableCalled,
                }
              ) => {
                if (!mutationCreateSharableCalled) {
                  createSharable({
                    variables: {
                      shareable: {
                        name: designName,
                      },
                      products: normalizedProducts,
                      designs: normalizedDesigns,
                    },
                  });
                }

                if (isDuplicatedDesign && !isUnknownError && !mutationUpdateShareableCalled) {
                  updateShareable({
                    variables: {
                      shareableId: duplicatedDesignData!.id,
                      shareable: {
                        name: duplicatedDesignData!.name,
                        email: duplicatedDesignData!.email,
                      },
                      products: normalizedProducts,
                      designs: normalizedDesigns,
                    },
                  });
                }

                if (mutationUpdateShareableLoading) {
                  console.log('mutationUpdateShareableLoading');
                  console.log('mutationUpdateShareableCalled', mutationUpdateShareableCalled);
                }

                const renderTryAgainModal = () => (
                  <Modal>
                    <Alert
                      alerts={[
                        {
                          type: 'warning',
                          title: 'Oh no! :(',
                          content: 'Sorry something went wrong while saving your design. Please try again.',
                          onClose: () => {
                            return this.setState({
                              showWarningModal: false,
                            });
                          },
                          primaryAction: 'Try Again',
                          onPrimaryAction: () => {
                            if (isDuplicatedDesign) {
                              updateShareable({
                                variables: {
                                  shareableId: duplicatedDesignData!.id,
                                  shareable: {
                                    name: duplicatedDesignData!.name,
                                    email: duplicatedDesignData!.email,
                                  },
                                  products: normalizedProducts,
                                  designs: normalizedDesigns,
                                },
                              });
                            } else {
                              createSharable({
                                variables: {
                                  shareable: {
                                    name: designName,
                                  },
                                  products: normalizedProducts,
                                  designs: normalizedDesigns,
                                },
                              });
                            }
                            return this.setState({
                              counter: 0,
                              loadingBarProgress: 0,
                              attemptsCounter: attemptsCounter + 1,
                              isUnknownError: false,
                            });
                          },
                        },
                      ]}
                    ></Alert>
                  </Modal>
                );

                const showLoading = mutationCreateSharableLoading || mutationUpdateShareableLoading;
                if (showLoading) {
                  // if we are in second between 0 to waitingTime - 1 second, we will render a random percentage
                  // if we are in the last second we will move to 99% and wait there until
                  // mutation completes, when we move to 100% done
                  if (!completed && counter < waitingTime - BUFFER_TIME) {
                    timeoutId = window.setTimeout(() => {
                      const increasedCounter = counter + 1;
                      const increasedProgress = getProgressPercentageForStep(increasedCounter, stepPercentage);
                      return this.setState({
                        counter: counter + 1,
                        loadingBarProgress: increasedProgress,
                      });
                    }, 1000);
                  } else if (!completed && counter < waitingTime) {
                    timeoutId = window.setTimeout(() => {
                      return this.setState({
                        counter: counter + 1,
                        loadingBarProgress: 99,
                      });
                    }, 1000);
                  }
                }

                const remainingTime: number = waitingTime - counter;
                let remainingTimeMessage = 'Calculating time...';
                if (counter > 0) {
                  remainingTimeMessage = `${remainingTime} second${remainingTime > 1 ? 's' : ''} left`;
                }
                if (completed) {
                  remainingTimeMessage = 'Done';
                }

                return (
                  <React.Fragment>
                    <div className="w-full px-3 py-1p5 bgc-blue-50 color-navy-500 p-relative">
                      <style jsx>
                        {`
                          .step {
                            font-size: 1.125rem;
                          }
                        `}
                      </style>
                      <p className="step w-full capitalize color-navy-700 italic fw-bold mb-1">generating artwork...</p>
                      <p className="flex justify-between m-0">
                        <span className="time lowercase">{remainingTimeMessage}</span>
                        <span className="percentage fw-bold">{loadingBarProgress}%</span>
                      </p>
                      <div className="w-full p-absolute pin-b pin-r pin-l bgc-blue-200">
                        <LoadingBar
                          className="p-absolute-important z-auto-important"
                          height={3}
                          color="#43446E"
                          progress={loadingBarProgress}
                        />
                      </div>
                    </div>
                    {showWarningModal && renderTryAgainModal()}
                    {showErrorModal && renderCreateDesignErrorModal()}
                  </React.Fragment>
                );
              }}
            </MutationUpdateSharable>
          );
        }}
      </MutationCreateSharable>
    );
  }
}
