import { DesignCartProduct, DesignCartDesign } from '../states/global/designCart';
import { Product, ProductColor } from 'inkp-product-sdk/types.g';
import {
  DESIGN_SIDE_ENUM,
  DesignSideInput,
  SHAPE_TYPE_ENUM,
  ShapeInput,
  ShareableDesignInput,
  ShareableProducItemInput,
} from 'inkp-design-sdk/types.g';
import { ShapeType } from '../interfaces/Canvas';
export function normalizeProducts(
  products: DesignCartProduct[],
  selectedProduct: Product,
  selectedColor: ProductColor
): ShareableProducItemInput[] {
  return products.map((product: any) => {
    const result = Object.assign({}, product);
    if (result.productId === selectedProduct.id) {
      result.selected = true;
    }
    result.colors = result.colors.map((color: string) => {
      return {
        name: color,
        selected: selectedColor.name === color ? true : undefined,
      };
    });

    delete result.designId;

    return result;
  });
}

export function normalizeDesigns(designs: DesignCartDesign[]): ShareableDesignInput[] {
  return designs.slice().map((design) => {
    const { userNote, sides } = design;

    const normalizedSides: DesignSideInput[] = sides.map((side) => {
      const { name, shapes, printZoneId, inchPerPx } = side;

      let normalizedName: DESIGN_SIDE_ENUM;
      switch (name) {
        case DESIGN_SIDE_ENUM.FRONT: {
          normalizedName = DESIGN_SIDE_ENUM.FRONT;
          break;
        }
        case DESIGN_SIDE_ENUM.BACK: {
          normalizedName = DESIGN_SIDE_ENUM.BACK;
          break;
        }
        case DESIGN_SIDE_ENUM.LEFT: {
          normalizedName = DESIGN_SIDE_ENUM.LEFT;
          break;
        }
        case DESIGN_SIDE_ENUM.RIGHT: {
          normalizedName = DESIGN_SIDE_ENUM.RIGHT;
          break;
        }
        default: {
          throw new Error(`Side ${name} wasn't found as part of our design sides, please verify information`);
        }
      }

      const normalizedShapes: ShapeInput[] = shapes.map((shape) => {
        const { type, ...rest } = shape;
        let normalizedShapeType: SHAPE_TYPE_ENUM = SHAPE_TYPE_ENUM.TEXT;

        switch (type) {
          case ShapeType.TEXT: {
            normalizedShapeType = SHAPE_TYPE_ENUM.TEXT;
            break;
          }
          case ShapeType.IMAGE: {
            normalizedShapeType = SHAPE_TYPE_ENUM.IMAGE;
            break;
          }
          case ShapeType.CLIPART: {
            normalizedShapeType = SHAPE_TYPE_ENUM.CLIPART;
            break;
          }
        }
        return {
          ...rest,
          type: normalizedShapeType,
        };
      });
      return {
        name: normalizedName,
        shapes: normalizedShapes,
        printZoneId,
        inchPerPx,
      };
    });
    return {
      userNote: userNote ? userNote : undefined,
      sides: normalizedSides,
    };
  });
}
